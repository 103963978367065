import * as React from "react";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "@lib";

const buttonVariants = cva(
  "w-full rounded-full items-center",
  {
    variants: {
      variant: {
        primary: "bg-gradient-to-r from-[#5D00D4] to-[#2A6EF9] text-white py-2 my-2",
        secondary: "bg-gradient-to-r from-[#FC4E1B] to-[#F8AF31] text-white py-2 my-2",
        download: "shadow-xl hover:bg-gray-100 text-sm justify-center sm:justify-start sm:w-[180px] px-4 py-2",
        search: "shadow-xl text-black h-8 w-8 p-2 bg-white mt-[25px]",
        renew: "bg-white shadow-lg p-2 sm:w-[180px]",
        subscription: "inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-gray-800 rounded shadow-md hover:bg-gray-900 focus:shadow-outline focus:outline-none",
        action: "inline-flex items-center px-4 py-2 bg-indigo-500 hover:bg-indigo-600 text-white text-sm font-medium rounded-full w-fit",
        delete: "shadow-xl bg-red-600 hover:bg-red-500 text-white text-sm justify-center sm:justify-start sm:w-[180px] px-4 py-2 transform-all duration-300",
      },
      size: {
        default: "",
        sm: "",
        lg: "",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> { }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

export { Button };