import "./App.css";
import React from "react";
import GenericRoutes from "./Routes";
import { NavbarProvider, AuthProvider } from '@lib';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <AuthProvider>
      <NavbarProvider>
        <div className="font-poppins">
          <GenericRoutes />
        </div>
        <ToastContainer />
      </NavbarProvider>
    </AuthProvider>
  );
}

export default App;
