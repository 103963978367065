import axios, { AxiosError } from 'axios';
import Cookies from "js-cookie";
import { omitBy, isNil } from 'lodash';

axios.defaults.baseURL = "https://reportapi.xeniapos.com/api/";

export const UserLogin = async (data: { UserName: any; Password: any; rememberMe: any; }) => {
  const LoginURL = `Login/SignIn?UserName=${data.UserName}&Password=${data.Password}`;
  try {
    const response = await axios.post(LoginURL, data);
    const expiration = data.rememberMe ? { expires: 30 } : undefined;
    Cookies.set('userToken', response.data.Token);
    Cookies.set('userType', response.data.USR_Type);
    Cookies.set('userId', response.data.USR_Uid);
    Cookies.set('userName', response.data.USR_Name);
    Cookies.set('userContact', response.data.USR_Contact, expiration);
    return response;
  } catch (error) {
    return error;
  }
};

export const UserLogout = async () => {
  const LogoutURL = `Login/Logout?Token=${Cookies.get("userToken")}`;
  try {
    const response = await axios.post(LogoutURL);
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    return { "error": axiosError.response?.data || 'An error occurred' };
  }
};

//User API
export const GetUserDashboard = async (data: { datefrom: any, dateto: any }) => {
  let UserDashboardURL = `DashBoard/ACCAdmin?from_date=${data.datefrom}&to_date=${data.dateto}&userId=${Cookies.get('userId')}`;
  if (Cookies.get('License_key')) UserDashboardURL += `&LicenceKey=${Cookies.get('License_key')}`;
  try {
    const response = await axios.get(UserDashboardURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const RegistrationNumberValidation = async (data: { phoneNumber: number }) => {
  const NumberValidationURL = `Otp/ValidateMobileNo?phoneNumber=${data.phoneNumber}`;
  try {
    const response = await axios.get(NumberValidationURL);
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    return { "error": axiosError.response?.data || 'An error occurred' };
  }
}

export const VerifyOTP = async (data: { phoneNumber: number, otp: number }) => {
  const VerifyOtpURL = `Otp/Verify?phoneNumber=${data.phoneNumber}&otp=${data.otp}`;
  try {
    const response = await axios.get(VerifyOtpURL);
    return response;
  } catch (error) {
    const axiosError = error as AxiosError;
    return { "error": axiosError.response?.data || 'An error occurred' };
  }
}

export const UserRegistration = async (data: any) => {
  const UserRegistrationURL = "Login/Registration";
  try {
    const response = await axios.post(UserRegistrationURL, data);
    return response;
  } catch (error) {
    return error;
  }
}

export const ForgetUserPassword = async (data: { mobileNo: number, Password: number }) => {
  const ForgetPasswordURL = `Login/ForgetPassword?mobileNo=${data.mobileNo}&Password=${data.Password}`;
  try {
    const response = await axios.post(ForgetPasswordURL, data);
    return response;
  } catch (error) {
    return error;
  }
}

export const GetAccountProfile = () => {
  const AccountProfileURL = "SubscriptionAccount/GetAccountProfile";
  try {
    const response = axios.get(AccountProfileURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const ChangeUserPassword = (data: any) => {
  const ChangePasswordURL = "Login/ChangePassword";
  try {
    const response = axios.post(ChangePasswordURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const GetAllBusinessUnit = () => {
  const AllBussinessUnitURL = `SubscriptionAccount/User/GetAllBusinesUnit?userId=${Cookies.get("userId")}`;
  try {
    const response = axios.get(AllBussinessUnitURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const GetAllSalesList = async (data: { index: number, size: number, datefrom: any; dateto: any; startLetter: string }) => {
  if (!data) return;
  const SalesData = omitBy(data, isNil);
  let AllSalesURL = `Sale/GetAllSaleList?pageIndex=${SalesData.index}&PageSize=${SalesData.size}&from_date=${SalesData.datefrom}&to_date=${SalesData.dateto}&userId=${Cookies.get('userId')}`;
  if (SalesData.startLetter) AllSalesURL += `&startLetter=${SalesData.startLetter}`;
  if (Cookies.get('License_key')) AllSalesURL += `&LicenseKey=${Cookies.get('License_key')}`;
  try {
    const response = await axios.get(AllSalesURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export const GetItemWiseSalesList = async (data: { index: number, size: number, datefrom: any; dateto: any; startLetter: string }) => {
  if (!data) return;
  const SalesData = omitBy(data, isNil);
  let ItemSalesURL = `Sale/GetItemWiseSale?pageIndex=${SalesData.index}&PageSize=${SalesData.size}&UserID=${Cookies.get('userId')}`;
  if (SalesData.datefrom) ItemSalesURL += `&from_date=${SalesData.datefrom}`;
  if (SalesData.dateto) ItemSalesURL += `&to_date=${SalesData.dateto}`;
  if (SalesData.startLetter) ItemSalesURL += `&startLetter=${SalesData.startLetter}`;
  if (Cookies.get('License_key')) ItemSalesURL += `&LicenseKey=${Cookies.get('License_key')}`;
  try {
    const response = await axios.get(ItemSalesURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export const GetCategoryWiseSalesList = async (data: { index: number, size: number, datefrom: any; dateto: any; startLetter: string; Category: string }) => {
  if (!data) return;
  const SalesData = omitBy(data, isNil);
  let CategorySalesURL = `Sale/GetCategoryWiseSale?pageIndex=${SalesData.index}&PageSize=${SalesData.size}&UserID=${Cookies.get('userId')}`;
  if (SalesData.datefrom) CategorySalesURL += `&from_date=${SalesData.datefrom}`;
  if (SalesData.dateto) CategorySalesURL += `&to_date=${SalesData.dateto}`;
  if (SalesData.startLetter) CategorySalesURL += `&startLetter=${SalesData.startLetter}`;
  if (SalesData.Category) CategorySalesURL += `&Category=${SalesData.Category}`;
  if (Cookies.get('License_key')) CategorySalesURL += `&LicenseKey=${Cookies.get('License_key')}`;
  try {
    const response = await axios.get(CategorySalesURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export const GetAllCategory = () => {
  const AllCategoryURL = `Category?userID=${Cookies.get('userId')}`;
  try {
    const response = axios.get(AllCategoryURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const GetEmployeeWiseSalesList = async (data: { index: number, size: number, datefrom: any; dateto: any; startLetter: string }) => {
  if (!data) return;
  const SalesData = omitBy(data, isNil);
  let EmployeeSalesURL = `Sale/GetEmployeWiseSale?pageIndex=${SalesData.index}&PageSize=${SalesData.size}&UserID=${Cookies.get('userId')}`;
  if (SalesData.datefrom) EmployeeSalesURL += `&from_date=${SalesData.datefrom}`;
  if (SalesData.dateto) EmployeeSalesURL += `&to_date=${SalesData.dateto}`;
  if (SalesData.startLetter) EmployeeSalesURL += `&startLetter=${SalesData.startLetter}`;
  if (Cookies.get('License_key')) EmployeeSalesURL += `&LicenseKey=${Cookies.get('License_key')}`;
  try {
    const response = await axios.get(EmployeeSalesURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export const GetCancelledWiseSalesList = async (data: { index: number, size: number, datefrom: any; dateto: any; startLetter: string }) => {
  if (!data) return;
  const SalesData = omitBy(data, isNil);
  let CancelledSalesURL = `Sale/GetCancelledSale?pageIndex=${SalesData.index}&PageSize=${SalesData.size}&UserID=${Cookies.get('userId')}`;
  if (SalesData.datefrom) CancelledSalesURL += `&from_date=${SalesData.datefrom}`;
  if (SalesData.dateto) CancelledSalesURL += `&to_date=${SalesData.dateto}`;
  if (SalesData.startLetter) CancelledSalesURL += `&startLetter=${SalesData.startLetter}`;
  if (Cookies.get('License_key')) CancelledSalesURL += `&LicenseKey=${Cookies.get('License_key')}`;
  try {
    const response = await axios.get(CancelledSalesURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

//Platform Admin API
export const GetDashboardData = async (data: { index: number, size: number, datefrom: any; dateto: any; }) => {
  if (!data) return;
  const DashboardURL = `DashBoard/PlatformAdmin?PageIndex=${data.index}&PageSize=${data.size}&from_date=${data.datefrom}&to_date=${data.dateto}`;
  try {
    const response = await axios.get(DashboardURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    });
    return response?.data;
  } catch (error) {
    return error;
  }
}

export const GetSubscriptionAccounts = async (data: { index: number, size: number, startLetter: string }) => {
  if (!data) return;
  const subscriptionAccountData = omitBy(data, isNil);
  let SubscriptionURL = `SubscriptionAccount/GetAllSubscriptionAccounts?pageIndex=${subscriptionAccountData.index}&pageSize=${subscriptionAccountData.size}`;
  if (subscriptionAccountData.startLetter) SubscriptionURL += `&startLetter=${subscriptionAccountData.startLetter}`;
  try {
    const response = await axios.get(SubscriptionURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    });
    return response?.data;
  } catch (error) {
    return error;
  }
}

export const GetBussinessUnit = (data: string) => {
  const BussinessUnitURL = `SubscriptionAccount/GetAllBusinesUnit?id=${data}`;
  try {
    const response = axios.get(BussinessUnitURL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export const GetSubscriptionPackage = async (data: { index: number, size: number, startLetter: string }) => {
  if (!data) return;
  const subscriptionPackageData = omitBy(data, isNil);
  let packageUrl = `SubscriptionPackages/GetSubscriptionPackage?pageIndex=${subscriptionPackageData.index}&pageSize=${subscriptionPackageData.size}`;
  if (subscriptionPackageData.startLetter) packageUrl += `&startLetter=${subscriptionPackageData.startLetter}`;
  try {
    const response = await axios.get(packageUrl, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    });
    return response?.data;
  } catch (error) {
    return error;
  }
}

export const InsertSubscriptionPackage = (data: any) => {
  if (!data) return;
  const SubscriptionURL = 'SubscriptionPackages/UpsertSubscriptionPackage';
  try {
    const response = axios.post(SubscriptionURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export const GetAccountExpiringReport = async (data: { index: any; size: any; startLetter: any; type: any; datefrom: any; dateto: any; }) => {
  if (!data) return;
  const filteredReportData = omitBy(data, isNil);
  let reportUrl = `SubscriptionAccount/GetAllSubscriptionAccountsReport?pageIndex=${filteredReportData.index}&PageSize=${filteredReportData.size}`;
  if (filteredReportData.startLetter) reportUrl += `&startLetter=${filteredReportData.startLetter}`;
  if (filteredReportData.type) reportUrl += `&Type=${filteredReportData.type}`;
  if (filteredReportData.datefrom) reportUrl += `&from_date=${filteredReportData.datefrom}`;
  if (filteredReportData.dateto) reportUrl += `&to_date=${filteredReportData.dateto}`;
  try {
    const response = await axios.get(reportUrl, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}

export const GetSubscriptionTransactionReport = async (data: { index: any; size: any; startLetter: any; type: any; datefrom: any; dateto: any; }) => {
  if (!data) return;
  const filteredReportData = omitBy(data, isNil);
  let reportUrl = `SubscriptionTranscation/GetAllSubscriptionTranscationReport?pageIndex=${filteredReportData.index}&PageSize=${filteredReportData.size}`;
  if (filteredReportData.startLetter) reportUrl += `&startLetter=${filteredReportData.startLetter}`;
  if (filteredReportData.type) reportUrl += `&Type=${filteredReportData.type}`;
  if (filteredReportData.datefrom) reportUrl += `&from_date=${filteredReportData.datefrom}`;
  if (filteredReportData.dateto) reportUrl += `&to_date=${filteredReportData.dateto}`;
  try {
    const response = await axios.get(reportUrl, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`
      }
    })
    return response;
  } catch (error) {
    return error;
  }
}