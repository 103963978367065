import React from "react";
import { AxiosError } from "axios";
import { Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Input, Button } from "@ui";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Excel from "@assets/excel.png";
import { useNavbarContext, GetSubscriptionTransactionReport } from "@lib";
import { Pagination } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ShowLoadingAnimation, ShowErrorLoadingAnimation, ShowNoDataAnimation } from '@common';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import { Default } from "@/lib/constant";

function SubscriptionReport() {
    const { isNavbarOpen } = useNavbarContext();
    const [pageIndex, setPageIndex] = React.useState(1);
    const [startLetter, setStartLetter] = React.useState("");
    const [type, setType] = React.useState('');
    const [datefrom, setDateFrom] = React.useState('');
    const [dateto, setDateTo] = React.useState('');
    const currentDate = new Date().toISOString().split('T')[0];

    const { isPending, isError, data, error, isFetching } = useQuery({
        queryKey: ['SubscriptionTransactionReport', pageIndex, startLetter, type, datefrom, dateto],
        queryFn: () => GetSubscriptionTransactionReport({ index: pageIndex, size: Default.pageSize, startLetter: startLetter, type: type, datefrom: datefrom, dateto: dateto }),
    })

    const handleChange = (_event: any, value: React.SetStateAction<number>) => {
        setPageIndex(value);
    };
    const handleTypeChange = (event: { target: { value: string; }; }) => {
        setType(event.target.value as string);
    };
    const handleFromDate = (event: { target: { value: string; }; }) => {
        setDateFrom(event.target.value as string);
    };
    const handleToDate = (event: { target: { value: string; }; }) => {
        setDateTo(event.target.value as string);
    };
    
    const handleDownloadExcel = async () => {
        if (!(Default?.pageSize * data?.data?.TotalPages)) {
            toast.error('No data to download', {
                position: "top-right",
                closeOnClick: true,
            });
            return;
        }
        const dataResponse = await GetSubscriptionTransactionReport({ index: pageIndex, size: Default.pageSize * data?.data?.TotalPages, startLetter: startLetter, type: type, datefrom: datefrom, dateto: dateto });
        if (dataResponse.status === 200) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Subscription Report');
            worksheet.columns = [
                { header: 'Invoice No', key: 'SUT_InvoiceNo', width: 15 },
                { header: 'Invoice Date', key: 'SUT_InvoiceDate', width: 15 },
                { header: 'Type', key: 'AccountType', width: 20 },
                { header: 'AccountName', key: 'AccountName', width: 30 },
                { header: 'Plan', key: 'PlanName', width: 10 },
                { header: 'MOP', key: 'SUT_MOP', width: 20 },
                { header: 'Payment Reference', key: 'SUT_PaymentRef', width: 20 },
                { header: 'Currency', key: 'SUT_Currency', width: 10 },
                { header: 'Amount', key: 'SUT_PaymentAmount', width: 15 },
                { header: 'Remark', key: 'SUT_Remarks', width: 15 },
            ];
            dataResponse?.data?.subscriptionTranscationReport.forEach((item: any) => {
                worksheet.addRow({
                    SUT_InvoiceNo: item.SUT_InvoiceNo,
                    SUT_InvoiceDate: item.SUT_InvoiceDate,
                    AccountType: item.AccountType,
                    AccountName: item.AccountName,
                    PlanName: item.PlanName,
                    SUT_MOP: item.SUT_MOP === "0" ? "Cash Transaction" : "Credit Transaction",
                    SUT_PaymentRef: item.SUT_PaymentRef,
                    SUT_Currency: item.SUT_Currency,
                    SUT_PaymentAmount: item.SUT_PaymentAmount.toFixed(2),
                    SUT_Remarks: item.SUT_Remarks,
                });
            });
            worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell((cell, colNumber) => {
                    if (worksheet.columns[colNumber - 1].key !== 'SUT_PaymentAmount' && 'AccountName') {
                        cell.alignment = { horizontal: 'center' };
                    }
                });
            });
            worksheet.getColumn('AccountName').eachCell(cell => {
                cell.alignment = { horizontal: 'left' };
            });
            worksheet.getColumn('SUT_PaymentAmount').eachCell(cell => {
                cell.alignment = { horizontal: 'right' };
            });
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), 'Subscription Transaction Report.xlsx');
            toast.success('File Downloaded', {
                position: "top-right",
                closeOnClick: true,
            });
        } else {
            toast.error('Download Failed ' + dataResponse.error || '', {
                position: "top-right",
                closeOnClick: true,
            });
        }
    };
    return (
        <div className="px-5">
            <div className="py-2 border-b-2">
                <h2 className="text-xl font-semibold py-2">Subscription Transaction Report</h2>
            </div>
            <div className="py-4 items-center rounded-xl drop-shadow-xl">
                <div className="flex flex-col lg:flex-row md:justify-between">
                    <div className="grid w-full sm:w-[250px]">
                        <span>Select Type</span>
                        <Select
                            value={type}
                            onChange={handleTypeChange}
                            className="h-8 rounded-[8px] shadow-xl outline-none"
                            displayEmpty
                        >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={"Trail"}>Trial</MenuItem>
                            <MenuItem value={"Subscribed"}>Subscribed</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className={`flex flex-col-reverse py-5 justify-between ${isNavbarOpen && window.innerWidth <= 768 ? "sm:flex-col-reverse" : "xl:flex-row"} xl:flex-row gap-2 items-end`}>
                    <div className="flex flex-col sm:flex-row gap-2 self-end items-end w-full">
                        <Button onClick={handleDownloadExcel} variant={"download"} className="flex gap-2 h-8 outline outline-1 outline-gray-200">
                            <img src={Excel} alt="excel" />
                            Export
                        </Button>
                        <Input className="w-full sm:w-[240px]" onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setStartLetter(e.target.value)} placeholder="Search" />
                    </div>
                    <div className="flex justify-end gap-3 w-full md:w-fit ">
                        <div className="grid w-fit">
                            <span>From</span>
                            <Input variant={"calender"} defaultValue={currentDate} type="date" onChange={handleFromDate} />
                        </div>
                        <div className="grid w-fit">
                            <span>To</span>
                            <Input variant={"calender"} defaultValue={currentDate} type="date" onChange={handleToDate} />
                        </div>
                    </div>
                </div>
            </div >
            <div className="overflow-auto">
                <Table className="text-xs">
                    <TableHeader>
                        <TableRow>
                            <TableHead>Sl.No</TableHead>
                            <TableHead>Invoice No</TableHead>
                            <TableHead>Invoice Date</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Business Account</TableHead>
                            <TableHead>Plan</TableHead>
                            <TableHead className="text-center">Mop</TableHead>
                            <TableHead>Payment Reference</TableHead>
                            <TableHead className="text-center">Currency</TableHead>
                            <TableHead className="text-center">Amount</TableHead>
                            <TableHead>Remark</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {!isError && !isPending && !isFetching && data?.data?.subscriptionTranscationReport?.map((report: any, index: number) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{report.SUT_InvoiceNo}</TableCell>
                                    <TableCell>{report.SUT_InvoiceDate}</TableCell>
                                    <TableCell>{report.AccountType}</TableCell>
                                    <TableCell>{report.AccountName}</TableCell>
                                    <TableCell>{report.PlanName}</TableCell>
                                    <TableCell className="text-center">{report.SUT_MOP === "0" ? "Cash Transaction" : "Credit Transaction"}</TableCell>
                                    <TableCell>{report.SUT_PaymentRef}</TableCell>
                                    <TableCell className="text-center">{report.SUT_Currency}</TableCell>
                                    <TableCell className="text-center">{report.SUT_PaymentAmount}</TableCell>
                                    <TableCell>{report.SUT_Remarks}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
            <div className="relative mb-10">
                {!isPending && !isFetching && !isError && !data?.data?.subscriptionTranscationReport?.length && <div className="w-64 m-auto text-center"><ShowNoDataAnimation /></div>}
                {isPending && <div className="w-32 m-auto text-center"><ShowLoadingAnimation /></div>}
                {!isError && data?.data?.subscriptionTranscationReport?.length ? <div className="flex justify-end">
                    <Pagination count={data?.data?.TotalPages} page={pageIndex} onChange={handleChange} size="medium" />
                </div> : null}
                {!isPending && isFetching && <div className="w-32 m-auto text-center absolute top-[50%] left-[50%]"><ShowLoadingAnimation /></div>}
                {!isPending && !isFetching && isError && (
                    <div className="w-32 m-auto font-bold text-center absolute top-[50%] left-[50%]">
                        <div><ShowErrorLoadingAnimation /></div>
                        <div>
                            {
                                (error instanceof AxiosError) ? error.response?.data?.Message : 'An error occurred'
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SubscriptionReport;