import React from "react";

function DeleteAccount() {
    return (
        <div className="p-4 md:mt-0 sm:p-8">
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Delete Account ?
            </h2>
            <div className="text-justify py-3">
                <div
                    className="bg-blue-50 border border-blue-400 rounded text-blue-800 text-sm p-4 flex items-start"
                >
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 mr-2"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <div className="w-full">
                        <p>
                            <span className="font-bold">Info: {" "}</span>
                            To delete your account from the Xenia Report Application, please send an email to <a className="text-blue-500 underline" href="mailto:xeniacare@spidertechnosoft.com?body=I want to Delete my Account!">xeniacare@spidertechnosoft.com</a> or call us at <a className="text-blue-500 underline" href="tel:+919995728888">+919995728888</a> to submit your request.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeleteAccount;