import React from "react";
import { AxiosError } from 'axios';
import { Button, Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Input } from "@ui";
import { Link } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
import { Pagination } from "@mui/material";
import { GetSubscriptionPackage } from "@lib";
import { ShowLoadingAnimation, ShowErrorLoadingAnimation, ShowNoDataAnimation } from '@common';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Default } from "@/lib/constant";

function SubscriptionPackage() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [pageIndex, setPageIndex] = React.useState(1);
    const [searchLetter, setSearchLetter] = React.useState("")
    const { isPending, isError, data, error, isFetching } = useQuery({
        queryKey: ['SubscriptionPackageDetails', pageIndex, searchLetter],
        queryFn: () => GetSubscriptionPackage({ index: pageIndex, size: Default.pageSize, startLetter: searchLetter }),
    })

    const handleChange = (event: any, value: React.SetStateAction<number>) => {
        setPageIndex(value);
    };
    return (
        <div className="px-5 py-1">
            <div className="py-2 border-b-2">
                <h2 className="text-xl font-semibold py-2">Subscription Package List</h2>
            </div>
            <Link className="flex justify-end py-4 w-full sm:w-fit sm:ml-auto" to={RouteNavigation.createSubscription}>
                <Button variant={"download"} className="w-full sm:w-[120px]">
                    Create new
                </Button>
            </Link>
            <div>
                <div className="flex justify-end">
                    <Input className="w-full sm:w-[240px]" onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSearchLetter(e.target.value)} placeholder="Search" /></div>
                <div className="overflow-auto">
                    <Table className="text-xs">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Sl</TableHead>
                                <TableHead>Subscription Name</TableHead>
                                <TableHead className="text-center">No. of Days</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead className="text-center">Action</TableHead>
                                <TableHead>Edit</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {!isError && !isPending && !isFetching && data?.SubscriptionPackageList?.map((Package: any, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{Package.SUP_Name}</TableCell>
                                        <TableCell className="text-center">{Package.SUP_Days}</TableCell>
                                        <TableCell>{Package.SUP_Active === true ? "Active" : "Inactive"}</TableCell>
                                        <TableCell>
                                            <div className="group relative text-center">
                                                <input type="checkbox" />
                                                <span className="hidden group-hover:block absolute top-[-44px] left-[-70px] transform bg-white border border-1 shadow-2xl py-2 px-4 rounded transition-opacity duration-300">
                                                    Action
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <span className="cursor-pointer" onClick={() => { queryClient.setQueryData(['packageData'], Package); navigate(RouteNavigation.createSubscription); }}>Edit</span>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div className="relative mb-10">
                    {!isPending && !isFetching && !isError && !data?.SubscriptionPackageList?.length && <div className="w-64 m-auto text-center"><ShowNoDataAnimation /></div>}
                    {isPending && isFetching && <div className="w-32 m-auto text-center"><ShowLoadingAnimation /></div>}
                    {!isError && data?.SubscriptionPackageList?.length ? <div className="flex justify-end">
                        <Pagination count={data?.TotalPages} page={pageIndex} onChange={handleChange} size="medium" />
                    </div> : null}
                    {!isPending && isFetching && <div className="w-32 m-auto text-center absolute top-[50%] left-[50%]"><ShowLoadingAnimation /></div>}
                    {!isPending && !isFetching && isError && (
                        <div className="w-32 m-auto font-bold text-center absolute top-[50%] left-[50%]">
                            <div><ShowErrorLoadingAnimation /></div>
                            <div>
                                {
                                    (error instanceof AxiosError) ? error.response?.data?.Message : 'An error occurred'
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPackage;