import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface CircularProgressBarProps {
    title: string;
    saleValue: number;
    salesProgressBar: boolean;
}

function CircularProgressBar({ title, saleValue, salesProgressBar }: CircularProgressBarProps) {
    const [value, setValue] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            if (value < saleValue) {
                setValue(value + 1);
            }
        }, 30);
    }, [value, saleValue]);
    return (
        <div className='w-40'>
            <CircularProgressbarWithChildren strokeWidth={5} value={value} maxValue={100}
                styles={buildStyles({
                    pathColor: salesProgressBar ? `rgba(14, 94, 240, ${value / 100})` : `rgb(207, 0, 90, ${value / 100})`,
                })}
            >
                {saleValue}
                <div style={{ fontSize: 12 }}>
                    <strong>{title}</strong>
                </div>
            </CircularProgressbarWithChildren>
        </div >

    );
}
export { CircularProgressBar };