import React, { useState, ChangeEvent } from "react";
import { Button } from "@ui";
import { useNavbarContext, InsertSubscriptionPackage } from "@lib";
import { useForm, SubmitHandler } from "react-hook-form"
import { toast } from 'react-toastify';
import { useMutation, useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
import { CreateSubscriptionType } from "@/lib/types";

function CreateSubscription() {
    const navigate = useNavigate();
    const { isNavbarOpen } = useNavbarContext();
    const { data: Package } = useQuery<CreateSubscriptionType>({ queryKey: ['packageData'], });
    const currentDate = new Date().toISOString().split('T')[0];
    const [selectedValue, setSelectedValue] = useState(true);
    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === 'true') {
            setSelectedValue(true);
        } else {
            setSelectedValue(false);
        }
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateSubscriptionType>({
        defaultValues: {
            SUP_Name: Package?.SUP_Name || undefined,
            SUP_Days: Package?.SUP_Days || undefined,
            SUP_Price: Package?.SUP_Price || undefined,
            SUP_InterPrice: Package?.SUP_InterPrice || undefined,
            SUP_Currency: Package?.SUP_Currency || undefined,
            SUP_InterCurrency: Package?.SUP_InterCurrency || undefined,
            SUP_Type: Package?.SUP_Type || undefined,
            SUP_Description: Package?.SUP_Description || undefined,
            SUP_CreatedBy: Package?.SUP_CreatedBy || undefined,
            SUP_ModifiedOn: Package?.SUP_ModifiedOn || undefined,
            SUP_ModifiedBy: Package?.SUP_ModifiedBy || undefined,
            SUP_Active: Package?.SUP_Active || undefined
        }
    });
    const mutate = useMutation({
        mutationFn: (data: any) => InsertSubscriptionPackage(data),
        onSuccess: () => {
            toast.success('Update Package Successful', {
                position: "top-right",
                closeOnClick: true,
            });
            navigate(RouteNavigation.package);
        },
        onError: () => {
            toast.error('Create Package Failed ', {
                position: "top-right",
                closeOnClick: true,
            });
        },
    })
    const handleSecondSubmit: SubmitHandler<CreateSubscriptionType> = async (data) => {
        const dataResponse = await InsertSubscriptionPackage(data);
        if (dataResponse.status === 200) {
            toast.success('Create Package Successful', {
                position: "top-right",
                closeOnClick: true,
            });
            navigate(RouteNavigation.package)
        } else {
            toast.error('Create Package Failed ' + dataResponse.error || '', {
                position: "top-right",
                closeOnClick: true,
            });
        }
    };

    const onSubmit = async (data: CreateSubscriptionType) => {
        // Conditionally call the appropriate submit handler based on some criteria
        if (Package) {
            const updatedData = {
                ...data,
                SUP_Uid: Package?.SUP_Uid || undefined,
                SUP_CreatedBy: Package?.SUP_CreatedBy,
                SUP_ModifiedBy: Cookies.get('userId'),
                SUP_ModifiedOn: currentDate,
                SUP_Active: !!selectedValue,
                SUP_Days: Number(data.SUP_Days),
                SUP_SUP_Price: Number(data.SUP_Price),
                SUP_InterPrice: Number(data.SUP_InterPrice),
            };
            mutate.mutate(updatedData);
        } else {
            const updatedData = {
                ...data,
                SUP_CreatedBy: Cookies.get('userId'),
                SUP_ModifiedBy: Cookies.get('userId'),
                SUP_ModifiedOn: currentDate,
                SUP_Active: !!selectedValue,
                SUP_Days: Number(data.SUP_Days),
                SUP_SUP_Price: Number(data.SUP_Price),
                SUP_InterPrice: Number(data.SUP_InterPrice)
            };
            handleSecondSubmit(updatedData);
        }
    };

    return (
        <div className="px-5">
            <div className="py-2 border-b-2">
                <h2 className="text-xl font-semibold py-2">{Package ? "Update" : "Create"} Subscription</h2>
            </div>
            <div className="bg-gray-100 rounded-b-2xl p-5 space-y-8">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`grid my-2 ${isNavbarOpen && window.innerWidth <= 768 ? "min-[540px]:grid-cols-1" : "min-[540px]:grid-cols-2"} lg:grid-cols-4 gap-2`}>
                        <span>
                            Subscription Name
                            <div className="mb-5">
                                <input
                                    {...register("SUP_Name", {
                                        required: "Name Required",
                                    })}
                                    className={'w-full rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none'}
                                    type="text" placeholder={"Subscription Name"}
                                />
                                {errors.SUP_Name && <span className="text-red-500 ml-2">{errors.SUP_Name.message}</span>}
                            </div>
                        </span>
                        <span>
                            Type
                            <div className="mb-5">
                                <input
                                    {...register("SUP_Type", {
                                        required: "Type Required",
                                    })}
                                    className={'w-full rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none'}
                                    type="text" placeholder={"Type"}
                                />
                                {errors.SUP_Type && <span className="text-red-500 ml-2">{errors.SUP_Type.message}</span>}
                            </div>
                        </span>
                        <span>
                            Days
                            <div>
                                <input
                                    {...register("SUP_Days", {
                                        required: "Days Required", pattern: {
                                            value: /^\d+$/,
                                            message: 'Enter Number',
                                        }
                                    })}
                                    className={'w-full rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none'}
                                    type="text" placeholder={"Days"}
                                />
                                {errors.SUP_Days && <span className="text-red-500 ml-2">{errors.SUP_Days.message}</span>}
                            </div>
                        </span>
                        <span className="my-5 md:my-0">
                            Status
                            <span>
                                <div className="flex gap-2 m-2">
                                    <label>
                                        <input
                                            type="radio"
                                            value="true"
                                            {...register("SUP_Active")}
                                            checked={selectedValue === true}
                                            onChange={handleRadioChange}
                                        />
                                        Active
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="false"
                                            {...register("SUP_Active")}
                                            checked={selectedValue === false}
                                            onChange={handleRadioChange}
                                        />
                                        Inactive
                                    </label>
                                </div>
                            </span>
                        </span>
                    </div>
                    <div className={`grid grid-cols-1 mt-5 ${isNavbarOpen && window.innerWidth <= 768 ? "sm:grid-cols-1" : "sm:grid-cols-2"} md:grid-cols-3 xl:grid-cols-4 gap-5`}>
                        <span className="mb-2">
                            Currency
                            <div>
                                <input
                                    {...register("SUP_Currency", {
                                        required: "Currency Required",
                                    })}
                                    className={'w-full rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none'}
                                    type="text" placeholder={"Currency"}
                                />
                                {errors.SUP_Currency && <span className="text-red-500 ml-2">{errors.SUP_Currency.message}</span>}
                            </div>
                        </span>
                        <span className="mb-2">
                            Price
                            <div>
                                <input
                                    {...register("SUP_Price", {
                                        required: "Price Required", pattern: {
                                            value: /^\d+$/,
                                            message: 'Enter Number',
                                        }
                                    })}
                                    className={'w-full rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none'}
                                    type="text" placeholder={"Price"}
                                />
                                {errors.SUP_Price && <span className="text-red-500 ml-2">{errors.SUP_Price.message}</span>}
                            </div>
                        </span>
                        <span className="mb-2">
                            Inter. Currency
                            <div>
                                <input
                                    {...register("SUP_InterCurrency", {
                                        required: "Currency Required",
                                    })}
                                    className={'w-full rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none'}
                                    type="text" placeholder={"Currency"}
                                />
                                {errors.SUP_InterCurrency && <span className="text-red-500 ml-2">{errors.SUP_InterCurrency.message}</span>}
                            </div>
                        </span>
                        <span className="mb-2">
                            Inter. Price
                            <div>
                                <input
                                    {...register("SUP_InterPrice", {
                                        required: "Price Required", pattern: {
                                            value: /^\d+([.,]\d+)?$/,
                                            message: 'Enter Number',
                                        }
                                    })}
                                    className={'w-full rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none'}
                                    type="text" placeholder={"Price"}
                                />
                                {errors.SUP_InterPrice && <span className="text-red-500 ml-2">{errors.SUP_InterPrice.message}</span>}
                            </div>
                        </span>
                    </div>
                    <div className="grid gap-2 mt-5">
                        <span className="mb-2">Description</span>
                        <textarea {...register("SUP_Description", { required: "Required" })} className="w-1/2 h-20 rounded-xl px-8 py-2 font-medium bg-white shadow-lg focus:outline-none" />
                        {errors.SUP_Description && <span className="text-red-500 ml-2">{errors.SUP_Description.message}</span>}
                    </div>
                    <div className="flex justify-end gap-5">
                        <Link to={RouteNavigation.package}>
                            <Button variant={"secondary"} className="w-fit px-5">Cancel</Button>
                        </Link>
                        <Button type="submit" className="w-fit px-5">{Package ? "Update" : "Create"}</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateSubscription;