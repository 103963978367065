import React from "react";
import { AxiosError } from 'axios';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Input, Button } from "@ui";
import Excel from "@assets/excel.png";
import { useNavbarContext, GetAccountExpiringReport } from "@lib";
import { Pagination } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ShowLoadingAnimation, ShowErrorLoadingAnimation, ShowNoDataAnimation } from '@common';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import { Default } from "@/lib/constant";

function AccountExpiringReport() {
    const { isNavbarOpen } = useNavbarContext();
    const [pageIndex, setPageIndex] = React.useState(1);
    const [startLetter, setStartLetter] = React.useState("");
    const [type, setType] = React.useState('');
    const [datefrom, setDateFrom] = React.useState('');
    const [dateto, setDateTo] = React.useState('');
    const { isPending, isError, data, error, isFetching } = useQuery({
        queryKey: ['AccountExpiringReport', pageIndex, startLetter, type, datefrom, dateto],
        queryFn: () => GetAccountExpiringReport({ index: pageIndex, size: Default.pageSize, startLetter: startLetter, type: type, datefrom: datefrom, dateto: dateto }),
    })

    const handleChange = (_event: any, value: React.SetStateAction<number>) => {
        setPageIndex(value);
    };
    const handleTypeChange = (event: { target: { value: string; }; }) => {
        setType(event.target.value as string);
    };
    const handleFromDate = (event: { target: { value: string; }; }) => {
        setDateFrom(event.target.value as string);
    };
    const handleToDate = (event: { target: { value: string; }; }) => {
        setDateTo(event.target.value as string);
    };
    const currentDate = new Date().toISOString().split('T')[0];
    const handleDownloadExcel = async () => {
        if (data?.data?.TotalPages !== 0) {
            toast.error('No data to download', {
                position: "top-right",
                closeOnClick: true,
            });
            return;
        }
        const dataResponse = await GetAccountExpiringReport({ index: pageIndex, size: Default.pageSize * data?.data?.TotalPages, startLetter: startLetter, type: type, datefrom: datefrom, dateto: dateto });
        if (dataResponse?.status === 200) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Business Report');
            worksheet.columns = [
                { header: 'Bussiness Account Name', key: 'SUA_Name', width: 30 },
                { header: 'Mobile No', key: 'SUA_Mobile', width: 15 },
                { header: 'Email', key: 'SUA_Email', width: 30 },
                { header: 'Registration', key: 'SUA_Registration', width: 15 },
                { header: 'Expiring', key: 'SUA_Expired', width: 15 },
                { header: 'Business Unit', key: 'SUA_BusinessUnit', width: 15 },
                { header: 'Trial/Subscribed', key: 'SUA_SubscriptionPlan', width: 15 },
                { header: 'Active', key: 'SUA_Status', width: 15 },
            ];
            dataResponse?.data?.subscriptionAccountReport.forEach((item: { SUA_Name: any; SUA_Mobile: any; SUA_Email: any; SUA_Registration: any; SUA_Expired: any; SUA_BusinessUnit: any; SUA_SubscriptionPlan: any; SUA_Status: any }) => {
                worksheet.addRow({
                    SUA_Name: item.SUA_Name,
                    SUA_Mobile: item.SUA_Mobile,
                    SUA_Email: item.SUA_Email,
                    SUA_Registration: item.SUA_Registration,
                    SUA_Expired: item.SUA_Expired,
                    SUA_BusinessUnit: item.SUA_BusinessUnit,
                    SUA_SubscriptionPlan: item.SUA_SubscriptionPlan,
                    SUA_Status: item.SUA_Status === "True" ? "Active" : "Inactive",
                });
            });
            worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                row.eachCell((cell, colNumber) => {
                    if (worksheet.columns[colNumber - 1].key !== 'SUA_Name') {
                        cell.alignment = { horizontal: 'center' };
                    }
                });
            });
            worksheet.getColumn('SUA_Name').eachCell(cell => {
                cell.alignment = { horizontal: 'left' };
            });
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), 'Business Report List.xlsx');
            toast.success('File Downloaded', {
                position: "top-right",
                closeOnClick: true,
            });
        } else {
            toast.error('Download Failed ' + dataResponse.error || '', {
                position: "top-right",
                closeOnClick: true,
            });
        }
    };
    return (
        <div className="px-5">
            <div className="py-2 border-b-2">
                <h2 className="text-xl font-semibold py-2">Account Expiring Report</h2>
            </div>
            <div className="py-4 items-center rounded-xl drop-shadow-xl">
                <div className="flex flex-col lg:flex-row md:justify-between">
                    <div className="grid w-full sm:w-[250px]">
                        <span>Select Type</span>
                        <Select
                            value={type}
                            onChange={handleTypeChange}
                            className="h-8 rounded-[8px] shadow-xl outline-none"
                            displayEmpty
                        >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={"Trail"}>Trial</MenuItem>
                            <MenuItem value={"Subscribed"}>Subscribed</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className={`flex flex-col-reverse py-5 justify-between ${isNavbarOpen && window.innerWidth <= 768 ? "sm:flex-col-reverse" : "xl:flex-row"} xl:flex-row gap-2 items-end`}>
                    <div className="flex flex-col sm:flex-row gap-2 self-end items-end w-full">
                        <Button onClick={handleDownloadExcel} variant={"download"} className="flex gap-2 h-8 outline outline-1 outline-gray-200">
                            <img src={Excel} alt="excel" />
                            Export
                        </Button>
                        <Input className="w-full sm:w-[240px]" onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setStartLetter(e.target.value)} placeholder="Search" />
                    </div>
                    <div className="flex justify-end gap-3 w-full md:w-fit ">
                        <div className="grid w-fit">
                            <span>From</span>
                            <Input variant={"calender"} className="w-full" defaultValue={currentDate} type="date" onChange={handleFromDate} />
                        </div>
                        <div className="grid w-fit">
                            <span>To</span>
                            <Input variant={"calender"} defaultValue={currentDate} type="date" onChange={handleToDate} />
                        </div>
                    </div>
                </div>
            </div >
            <div className="overflow-auto">
                <Table className="text-xs">
                    <TableHeader>
                        <TableRow>
                            <TableHead>Sl.No</TableHead>
                            <TableHead>Bussiness Account Name</TableHead>
                            <TableHead>Mobile No</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Registration</TableHead>
                            <TableHead>Expiring</TableHead>
                            <TableHead className="text-center">Business Unit</TableHead>
                            <TableHead className="text-center">Trial / <br /> Subscribed</TableHead>
                            <TableHead>Active</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {!isPending && !isFetching && !isError && data?.data?.subscriptionAccountReport?.length ? data?.data?.subscriptionAccountReport?.map((report: any, index: number) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{report.SUA_Name}</TableCell>
                                    <TableCell>{report.SUA_Mobile}</TableCell>
                                    <TableCell>{report.SUA_Email}</TableCell>
                                    <TableCell>{report.SUA_Registration}</TableCell>
                                    <TableCell>{report.SUA_Expired}</TableCell>
                                    <TableCell className="text-center">{report.SUA_BusinessUnit}</TableCell>
                                    <TableCell className="text-center">{report.SUA_SubscriptionPlan}</TableCell>
                                    <TableCell>{report.SUA_Status}</TableCell>
                                </TableRow>
                            )
                        })
                            : null}
                    </TableBody>
                </Table>
            </div>
            <div className="relative mb-10">
                {!isPending && !isFetching && !isError && !data?.data?.subscriptionAccountReport?.length && <div className="w-64 m-auto text-center"><ShowNoDataAnimation /></div>}
                {isPending && <div className="w-32 m-auto text-center"><ShowLoadingAnimation /></div>}
                {data?.data?.subscriptionAccountReport?.length && !isError ? <div className="flex justify-end">
                    <Pagination count={data?.data?.TotalPages} page={pageIndex} onChange={handleChange} size="medium" />
                </div> : null}
                {!isPending && isFetching && <div className="w-32 m-auto text-center absolute top-[50%] left-[50%]"><ShowLoadingAnimation /></div>}
                {!isPending && !isFetching && isError && (
                    <div className="w-32 m-auto font-bold text-center absolute top-[50%] left-[50%]">
                        <div><ShowErrorLoadingAnimation /></div>
                        <div>
                            {
                                (error instanceof AxiosError) ? error.response?.data?.Message : 'An error occurred'
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AccountExpiringReport;