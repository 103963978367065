import * as React from "react";
import { VariantProps, cva } from "class-variance-authority";
import { FC } from "react";

const InputVariants = cva(
    "flex h-8 px-3 py-2 text-sm border focus:outline-none",
    {
        variants: {
            variant: {
                default: "w-[150px] p-2 rounded-[8px] shadow-xl",
                calender: "p-2 rounded-[8px] shadow-xl w-full md:w-[150px]",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

type InputProps = VariantProps<typeof InputVariants> & {
    className?: string,
    type?: string,
    placeholder?: string,
    defaultValue?: string | undefined,
    value?: string | undefined,
    onChange?: () => void
}

const Input: FC<InputProps> = (
    ({ className, variant, type, value, placeholder, defaultValue, onChange }: InputProps) => {
        return (
            <input
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                className={InputVariants({ variant, className })}
            />
        )
    }
)
Input.displayName = "Input"

export { Input };