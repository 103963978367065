import React from "react";
import { Input, CircularProgressBar } from "@ui";
import TotalBill from '@assets/total-bill.svg';
import TotalSale from '@assets/total-sale.svg';
import Circle1 from '@assets/mask-circle-1.svg';
import Circle2 from '@assets/mask-circle-2.svg';
import Circle3 from '@assets/mask-circle-3.svg';
import { GetAllBusinessUnit, GetUserDashboard } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { Select, MenuItem } from "@mui/material";
import Cookies from "js-cookie";

export default function HomePage() {
    const currentDate = new Date().toISOString().split('T')[0];
    const [dateFrom, setDateFrom] = React.useState(Cookies.get('From_date') || currentDate);
    const [dateTo, setDateTo] = React.useState(Cookies.get('To_date') || currentDate);
    const [bussinessUnit, setBussinessUnit] = React.useState(Cookies.get('License_key') || "")
    const { data: bussinessData } = useQuery({
        queryKey: ['AllBussinessUnit'],
        queryFn: () => GetAllBusinessUnit(),
    })
    const { data: dashboardData } = useQuery({
        queryKey: ['dashboard', dateFrom, dateTo, bussinessUnit],
        queryFn: async () => GetUserDashboard({ datefrom: dateFrom, dateto: dateTo })
    })

    const handleFromDate = (event: { target: { value: string; }; }) => {
        setDateFrom(event.target.value as string);
        Cookies.set('From_date', event.target.value as string);
    };
    const handleToDate = (event: { target: { value: string; }; }) => {
        setDateTo(event.target.value as string);
        Cookies.set('To_date', event.target.value as string);
    };
    const handleBussinessUnit = (event: { target: { value: any; }; }) => {
        const selectedValue = event.target.value;
        setBussinessUnit(selectedValue);
        Cookies.set('License_key', selectedValue);
    };
    return (
        <div className="px-4 py-6 mb-2 h-screen bg-bottom bg-cover bg-no-repeat">
            <div className="py-4 items-center rounded-xl drop-shadow-xl">
                <div className={`flex flex-col lg:flex-row gap-2 items-center`}>
                    <div className="grid w-fit self-start">
                        <span>Bussiness Unit</span>
                        <Select
                            value={bussinessUnit}
                            onChange={handleBussinessUnit}
                            defaultValue={Cookies.get('License_key') || ""}
                            className="h-8 w-56 rounded-[8px] shadow-xl outline-none"
                            displayEmpty
                        >
                            <MenuItem value={""}>All</MenuItem>
                            {bussinessData?.data
                                ?.slice()
                                .sort((a: { BUS_Name: string; }, b: { BUS_Name: any; }) => a.BUS_Name.localeCompare(b.BUS_Name))
                                .map((unit: any, index: number) => (
                                    <MenuItem key={index} value={unit.BUS_LicenceKey}>
                                        {unit.BUS_Name}({unit.BUS_LicenceKey})
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                    <div className={`flex flex-row gap-2 justify-end w-full`}>
                        <div className="grid w-fit">
                            <span>From</span>
                            <Input variant={"calender"} className="w-full" defaultValue={dateFrom} type="date" onChange={handleFromDate} />
                        </div>
                        <div className="grid w-fit">
                            <span>To</span>
                            <Input variant={"calender"} defaultValue={dateTo} type="date" onChange={handleToDate} />
                        </div>
                    </div>
                </div>
            </div >
            <section>
                <div className="grid grid-cols-1 lg:grid-cols-2 p-5 gap-8">
                    <div className="h-[120px] relative w-full bg-primary-background rounded-3xl">
                        <div className="absolute top-0 w-full px-6 pt-4 text-white">
                            <div className="flex justify-between">
                                <span>Total Bill</span>
                                <img className="w-8 mr-10" src={TotalBill} alt="total bill" />
                            </div>
                            <span className="text-xl pt-16 absolute bottom-[-50px]">Number: <b>{dashboardData?.TotalBills || 0}</b></span>
                        </div>
                        <img className="absolute top-0 left-14" src={Circle1} alt="masked-circle" />
                        <img className="absolute top-0 right-14" src={Circle2} alt="masked-circle" />
                        <img className="absolute bottom-0 right-0" src={Circle3} alt="masked-circle" />
                    </div>
                    <div className="h-[120px] relative w-full bg-secondary-background rounded-3xl">
                        <div className="absolute top-0 w-full px-6 pt-4 text-white">
                            <div className="flex justify-between">
                                <span>Total Sales</span>
                                <img className="w-12 mr-10" src={TotalSale} alt="total bill" />
                            </div>
                            <span className="text-xl pt-16 absolute bottom-[-60px]">Amount: <b>{parseFloat(dashboardData?.TotalSaleAmount).toFixed(2) || 0}</b></span>
                        </div>
                        <img className="absolute top-0 left-14" src={Circle1} alt="masked-circle" />
                        <img className="absolute top-0 right-14" src={Circle2} alt="masked-circle" />
                        <img className="absolute bottom-0 right-0" src={Circle3} alt="masked-circle" />
                    </div>
                </div>
            </section>

            <section className="px-5">
                <h2 className="text-xl font-semibold">Daily Summary</h2>
                <div className="flex justify-evenly py-5 flex-wrap gap-4">
                    <CircularProgressBar salesProgressBar title={"Cash Transaction"} saleValue={dashboardData?.CashTransactions} value={dashboardData?.CashTransactions} />
                    <CircularProgressBar salesProgressBar title={"Card Transaction"} saleValue={dashboardData?.CardTransactions} value={dashboardData?.CardTransactions} />
                    <CircularProgressBar salesProgressBar title={"Credit Transaction"} saleValue={dashboardData?.CreditTransactions} value={dashboardData?.CreditTransactions} />
                </div>
                <div className="p-2 my-2 bg-[#d7e5ff]">
                    <b>Total No Of Sales:</b> {dashboardData?.TotalBills || 0}
                </div>
                <div className="flex justify-evenly py-5 flex-wrap gap-4">
                    <CircularProgressBar title={"Gross Amount"} saleValue={parseFloat(dashboardData?.TotalGrossAmount).toFixed(2)} value={parseFloat(dashboardData?.TotalGrossAmount).toFixed(2)} />
                    <CircularProgressBar title={"Discount Amount"} saleValue={parseFloat(dashboardData?.DiscountAmount).toFixed(2)} value={parseFloat(dashboardData?.DiscountAmount).toFixed(2)} />
                    <CircularProgressBar title={"Tax Amount"} saleValue={parseFloat(dashboardData?.TaxAmount).toFixed(2)} value={parseFloat(dashboardData?.TaxAmount).toFixed(2)} />
                    <CircularProgressBar title={"Round Off Amount"} saleValue={parseFloat(dashboardData?.RoundOffAmount).toFixed(2)} value={parseFloat(dashboardData?.RoundOffAmount).toFixed(2)} />
                </div>
                <div className="p-2 mb-5 bg-[#d7e5ff]">
                    <b>Bill Amount:</b> {dashboardData?.TotalSaleAmount || 0}
                </div>
            </section>
            <div className="h-1"></div>
        </div >
    )
}