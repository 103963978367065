import React, { useEffect, useState, useRef } from "react"
import "./Header.scss";
import { RxHamburgerMenu } from "react-icons/rx";
import { NavBar } from "@common";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RouteNavigation } from "../../../Routes";
import { IoLogOutOutline } from "react-icons/io5";
import { useNavbarContext } from '@lib';
import Cookies from "js-cookie";

export function Header() {
    const [logoutVisible, setLogoutVisible] = useState(false);
    const { isNavbarOpen, toggleNavbar } = useNavbarContext();
    const popupRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const UserName = Cookies.get('userName');
    const UserContact = Cookies.get('userContact');

    const handleLogout = () => {
        const allCookies = Cookies.get();
        Object.keys(allCookies).forEach((cookieName) => {
            Cookies.remove(cookieName);
        });
        navigate("/");
        navigate(RouteNavigation.login);
    };

    const showLogout = () => {
        setLogoutVisible(!logoutVisible);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setLogoutVisible(false);
        }
    };

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (logoutVisible) {
                handleClickOutside(event);
            }
        };
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [logoutVisible]);

    return (
        <>
            <NavBar />
            <div className={`${isNavbarOpen ? "sm:pl-[18rem]" : "pl-5"} header-background py-4 flex flex-col md:flex-row justify-between`}>
                <div className="flex items-center">
                    {!isNavbarOpen ? <RxHamburgerMenu onClick={() => toggleNavbar(!isNavbarOpen)} className="cursor-pointer" size={"30px"} /> : null}
                    <div className="text-2xl m-auto sm:ml-2">
                        Xenia Report App
                    </div>
                </div>
                <div className="group flex gap-4 justify-end pt-3 px-5 cursor-pointer" onClick={showLogout}>
                    <div className="grid">
                        <span className="text-[15px] group-hover:font-semibold group-hover:text-[16px] transform-all duration-200">{UserName}</span>
                        <span className="text-[12px] group-hover:font-semibold group-hover:text-[14px] transform-all duration-200">Username: {UserContact}</span>
                    </div>
                    <div className="relative">
                        <FaUserCircle size={"40px"} />
                        {logoutVisible && (
                            <div ref={popupRef} className="absolute top-[30px] z-50 left-[-180px] bg-white text-black p-4 border-2 w-52 rounded mt-2">
                                <div className="flex items-center gap-2 cursor-pointer" onClick={handleLogout}>
                                    <IoLogOutOutline />
                                    <span>Logout</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}