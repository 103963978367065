import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { ChangeUserPassword } from "@lib";
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

type ChangePassword = {
    Password: string,
    NewPassword: string,
    ConfirmPassword: string,
}

export default function ChangePassword() {
    const navigate = useNavigate();
    const formSchema = yup.object().shape({
        Password: yup.string()
            .required("Password is required")
            .min(4, "Password too short"),
        NewPassword: yup.string()
            .required("Enter new password")
            .min(4, "Password too short"),
        ConfirmPassword: yup.string()
            .required("Confirm Password is required")
            .oneOf([yup.ref("NewPassword")], "Passwords do not match")
    });
    const { register, handleSubmit, formState: { errors } } = useForm<ChangePassword>({
        mode: "onTouched",
        resolver: yupResolver(formSchema),
        defaultValues: {
            Password: "",
            NewPassword: "",
            ConfirmPassword: "",
        }
    });
    const userName = Cookies.get("userContact");
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const onSubmit: SubmitHandler<ChangePassword> = async (data) => {
        const userId = Cookies.get("userId");
        let UpdatedData = {
            User_Uid: userId,
            Password: data.Password,
            ConfirmPassword: data.ConfirmPassword
        }
        const dataResponse = await ChangeUserPassword(UpdatedData);
        if (dataResponse.status === 202) {
            toast.success('Password Changed Successfully', {
                position: "top-right",
                closeOnClick: true,
            });
            navigate(RouteNavigation.dashboard)
        } else {
            toast.error('Password Change Failed :' + dataResponse.error || '', {
                position: "top-right",
                closeOnClick: true,
            });
        }
    };
    return (
        <div className="p-6 bg-white rounded-lg shadow md:mt-0 sm:p-8">
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Change Password
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User Name</label>
                        <input disabled={true} className="w-full border-2 shadow-lg rounded-xl p-2 focus:outline-none" placeholder="User Name" value={userName} />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Old Password</label>
                        <input className="w-full border-2 shadow-lg rounded-xl p-2 focus:outline-none" type="password" placeholder="********" {...register("Password")} />
                        <p className="text-red-500 ml-5">{errors.Password?.message}</p>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                        <div className="relative">
                            <input className="w-full border-2 shadow-lg rounded-xl p-2 focus:outline-none" type={passwordVisibility ? "text" : "password"} placeholder="********" {...register("NewPassword")} />
                            <p className="absolute top-3 right-5 cursor-pointer" onClick={e => setPasswordVisibility(!passwordVisibility)}>
                                {passwordVisibility ? <IoEyeOff size={"20px"} color="#9ca3af" /> : <IoEye size={"20px"} color="#9ca3af" />}
                            </p>
                        </div>
                        <p className="text-red-500 ml-5">{errors.NewPassword?.message}</p>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                        <input className="w-full border-2 shadow-lg rounded-xl p-2 focus:outline-none" type="password" placeholder="********" {...register("ConfirmPassword")} />
                        <p className="text-red-500 ml-5">{errors.ConfirmPassword?.message}</p>
                    </div>
                </div>
                <button type="submit" className="w-full my-4 border-2 shadow-lg rounded-xl p-2 focus:outline-none bg-blue-700 hover:bg-blue-600 text-white">Reset passwod</button>
            </form>
        </div>
    )
}
