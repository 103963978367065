import Cookies from "js-cookie";
import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface ProtectedPagesProps {
  children: ReactNode;
}

export const PlatformAdminProtectedPages: React.FC<ProtectedPagesProps> = ({ children }) => {
  const loggedUserType = Cookies.get("userType");
  let adminLoggedIn: boolean;
  if (loggedUserType === "PLATFORM_ADMIN") {
    adminLoggedIn = true;
  } else {
    adminLoggedIn = false;
  }
  if (adminLoggedIn) {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return children;
};

export const UserProtectedPages : React.FC<ProtectedPagesProps> = ({ children }) => {
  const loggedUserType = Cookies.get("userType");
  let userLoggedIn: boolean;
  if (loggedUserType === "ACC_Admin") {
    userLoggedIn = true;
  } else {
    userLoggedIn = false;
  }
  if (userLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};
