import React, { createContext, useContext, useEffect, useState } from 'react';

interface NavbarContextProps {
  isNavbarOpen: boolean;
  toggleNavbar: () => void;
}

const NavbarContext = createContext<NavbarContextProps | undefined>(undefined);

export const useNavbarContext = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error('useNavbarContext must be used within a NavbarProvider');
  }
  return context;
};

export const NavbarProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(window.innerWidth >= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsNavbarOpen(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleNavbar = () => {
    setIsNavbarOpen((prevIsNavbarOpen) => !prevIsNavbarOpen);
  };

  return (
    <NavbarContext.Provider value={{ isNavbarOpen, toggleNavbar }}>
      {children}
    </NavbarContext.Provider>
  );
};
