import React from "react";
import { Route, Routes } from "react-router-dom";
import * as Page from "./pages";
import { PlatformAdminProtectedPages, UserProtectedPages } from "@common";

export const RouteNavigation = {
  // Splash screen
  login: "/",
  signup: "/signup",
  resetPassword: "/reset-password",

  // Admin routes
  adminDashboard: "/admin/dashboard",
  subscription: "/admin/subscription",
  package: "/admin/subscription-package",
  accountExpiringReport: "/admin/account-expiring-report",
  subscriptionReport: "/admin/subscription-transaction-report",
  createSubscription: "/admin/create-subscription",
  renewal: "/admin/subscription/renewal",

  // User routes
  dashboard: "/dashboard",
  listSales: "/sales-report/list-sales",
  itemSale: "/sales-report/item-sales",
  categorySale: "/sales-report/category-sales",
  employeeSale: "/sales-report/employee-sales",
  cancelledSale: "/sales-report/cancelled-sales",
  changePassword: "/change-password",
  acountProfile: "/account",
  deleteAccount: "/account/delete-account",
};

const GenericRoutes = () => {
  return (
    <Routes>
      {/* Splash Screens */}
      <Route path={RouteNavigation.login} element={<Page.Login />} />
      <Route path={RouteNavigation.signup} element={<Page.SignUp />} />
      <Route
        path={RouteNavigation.resetPassword}
        element={<Page.ResetPassword />}
      />

      {/* Admin routes */}
      <Route
        path="/"
        element={
          <UserProtectedPages>
            <Page.AdminDashboard />
          </UserProtectedPages>
        }
        children={
          <>
            <Route
              path={RouteNavigation.adminDashboard}
              element={<Page.AdminHomePage />}
            />
            <Route
              path={RouteNavigation.renewal}
              element={<Page.RenewalSubscription />}
            />
            <Route
              path={RouteNavigation.subscription}
              element={<Page.SubscriptionAccount />}
            />
            <Route
              path={RouteNavigation.package}
              element={<Page.SubscriptionPackage />}
            />
            <Route
              path={RouteNavigation.createSubscription}
              element={<Page.CreateSubscription />}
            />
            <Route
              path={RouteNavigation.accountExpiringReport}
              element={<Page.AccountExpiringReport />}
            />
            <Route
              path={RouteNavigation.subscriptionReport}
              element={<Page.SubscriptionReport />}
            />
          </>
        }
      />

      {/* User routes */}
      <Route
        path="/"
        element={
          <PlatformAdminProtectedPages>
            <Page.CustomerDashboard />
          </PlatformAdminProtectedPages>
        }
        children={
          <>
            <Route
              path={RouteNavigation.dashboard}
              element={<Page.CustomerHomePage />}
            />
            <Route
              path={RouteNavigation.listSales}
              element={<Page.ListOfSales />}
            />
            <Route
              path={RouteNavigation.itemSale}
              element={<Page.ItemWiseSale />}
            />
            <Route
              path={RouteNavigation.categorySale}
              element={<Page.CategoryWiseSale />}
            />
            <Route
              path={RouteNavigation.employeeSale}
              element={<Page.EmployeeWiseSale />}
            />
            <Route
              path={RouteNavigation.cancelledSale}
              element={<Page.CancelledSale />}
            />
            <Route
              path={RouteNavigation.acountProfile}
              element={<Page.Profile />}
            />
            <Route
              path={RouteNavigation.deleteAccount}
              element={<Page.DeleteAccount />}
            />
            <Route
              path={RouteNavigation.changePassword}
              element={<Page.ChangePassword />}
            />
          </>
        }
      />
    </Routes>
  );
};
export default GenericRoutes;
