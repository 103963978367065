import React from "react";
import { Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Button } from "@ui";
import { GetAccountProfile } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { RouteNavigation } from "@/Routes";
import RazorpayButton from "@/components/common/PaymentGateway";

function AccountProfile() {
    const { isError, data, } = useQuery({
        queryKey: ['AccountProfile'],
        queryFn: () => GetAccountProfile(),
    })
    const originalDate = data ? new Date(data?.data?.expireDate) : "";
    const formattedDate = originalDate ? `${originalDate.getMonth() + 1}/${originalDate.getDate()}/${originalDate.getFullYear()}` : "";
    let SubscriptionDetails: any;
    if (data?.data?.remainingDays[0] === "-") {
        SubscriptionDetails = "Account Expired Please Renew Subscription";
    } else {
        SubscriptionDetails = `Only ${data ? data?.data?.remainingDays : 0} days left, If you wish to continue using our service renew your subscription.`
    }
    return (
        <div className="p-4 md:mt-0 sm:p-8">
            <div className="flex justify-between py-5">
                <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                    Account Profile
                </h2>
                <Link to={RouteNavigation.deleteAccount}>
                    <Button variant={"delete"}>
                        Delete Account
                    </Button>
                </Link>
            </div>
            <div className="bg-[#D2E1FF] px-6 py-4 items-center rounded-xl">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <div>
                        Account Valid Till : <b>{formattedDate || 0}</b><br />
                        <span className="text-sm text-red-500">
                            {SubscriptionDetails}
                        </span>
                    </div>
                    <div className="w-full sm:w-[180px] text-end items-center">
                        <RazorpayButton />
                    </div>
                </div>
            </div >
            <>
                <div className="py-6">
                    <div className="overflow-auto">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="text-center">Plan Name</TableHead>
                                    <TableHead className="text-center">Plan Validity</TableHead>
                                    <TableHead className="text-center">Amount</TableHead>
                                    <TableHead className="text-center">Subscribe</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {!isError && data?.data?.SubscriptionPackageList?.map((profile: any, index: number) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell className="text-center p-2">{profile.SUP_Name}</TableCell>
                                            <TableCell className="text-center p-2">For {profile.SUP_Days} Days</TableCell>
                                            <TableCell className="text-center p-2 default_font">{profile.SUP_Price} ₹ </TableCell>
                                            <TableCell className="text-center p-2">
                                                <Button variant={"action"}>
                                                    Get Now
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </>
        </div>
    )
}
export default AccountProfile;