import React, { ReactNode, useState, useEffect } from "react";
import "./NavBar.scss";
import Logo from "@assets/logo.png";
import SalesReport from "@assets/sales-report.svg";
import Dashboard from "@assets/dashboard.svg";
import PurchaseReport from "@assets/purchase-report.svg";
import StockReport from "@assets/stock-report.svg";
import ActivityReport from "@assets/activity-report.svg";
import UserSettings from "@assets/user-settings.svg";
import ChangePassword from "@assets/change-password.svg";
import { IoHelpCircleOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { RouteNavigation } from "../../../Routes";
import { AiOutlineProfile } from "react-icons/ai";
import { MdOutlineCardMembership, MdOutlineManageAccounts } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { TbReport } from "react-icons/tb";
import { useNavbarContext } from '@lib';
import Cookies from "js-cookie";
import { NavLink, useLocation } from "react-router-dom";

type AccordionProps = {
    title: string;
    image: string;
    content: ReactNode;
};

export function NavBar() {
    const { isNavbarOpen, toggleNavbar } = useNavbarContext();
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    useEffect(() => {
        const isChildRoute = location.pathname.startsWith('/sales-report');
        setExpanded(isChildRoute);
    }, [location.pathname]);

    let AdminMenu: boolean;
    if (Cookies.get('userType') === 'PLATFORM_ADMIN') {
        AdminMenu = true;
    } else {
        AdminMenu = false;
    }

    const handleNavbarClick = () => {
        if (window.innerWidth <= 768) {
            toggleNavbar();
        }
    };
    function Accordion({ title, image, content }: AccordionProps) {
        const toggleExpanded = () => setExpanded((current) => !current)
        return (<>
            <div className="flex justify-between cursor-pointer" onClick={toggleExpanded}>
                <div className="flex items-center gap-2">
                    <img src={image} alt={title} />
                    <span>{title}</span>
                </div>
                <div>
                    <IoIosArrowForward className={`${expanded ? "rotate-90" : ""} text-gray-400 transition-all`} />
                </div>
            </div>
            <div className={`pt-0 overflow-hidden transition-[max-height] duration-500 ease-in ${expanded ? "max-h-96" : "max-h-0"}`}>
                {content}
            </div>
        </>
        )
    }

    return (
        <aside className={`${isNavbarOpen ? "visible" : "invisible"} bg-white w-[18rem] rounded-r-3xl min-h-screen p-4 shadow-2xl fixed top-0 bottom-0 z-20 left-0`}>
            <div className="flex justify-between mb-8">
                <img src={Logo} alt="logo" />
                <div className="self-center">
                    <AiOutlineClose className="cursor-pointer" onClick={e => toggleNavbar()} />
                </div>
            </div>
            <nav>
                <ul className="space-y-5 font-medium">
                    {/* Section A ADMIN SIDE */}
                    {AdminMenu ? <li>
                        <NavLink
                            to={RouteNavigation.adminDashboard}
                            onClick={handleNavbarClick}
                            className={({ isActive, isPending }) =>
                                `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                }`
                            }
                        >
                            <img src={Dashboard} alt="dashboard" />
                            <span>Dashboard</span>
                        </NavLink>
                    </li> : null}
                    {AdminMenu ? <li>
                        <NavLink
                            to={RouteNavigation.subscription}
                            onClick={handleNavbarClick}
                            className={({ isActive, isPending }) =>
                                `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                }`
                            }
                        >
                            <MdOutlineManageAccounts />
                            <span>Subscription Account</span>
                        </NavLink>
                    </li> : null}
                    {AdminMenu ? <li>
                        <NavLink
                            to={RouteNavigation.accountExpiringReport}
                            onClick={handleNavbarClick}
                            className={({ isActive, isPending }) =>
                                `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                }`
                            }
                        >
                            <TbReport />
                            <span>Account Expiring Report</span>
                        </NavLink>
                    </li> : null}
                    {AdminMenu ? <li>
                        <NavLink
                            to={RouteNavigation.subscriptionReport}
                            onClick={handleNavbarClick}
                            className={({ isActive, isPending }) =>
                                `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                }`
                            }
                        >
                            <GrTransaction />
                            <span>Subscription Transaction Report</span>
                        </NavLink>
                    </li> : null}
                    {AdminMenu ? <li>
                        <NavLink
                            to={RouteNavigation.package}
                            onClick={handleNavbarClick}
                            className={({ isActive, isPending }) =>
                                `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                }`
                            }
                        >
                            <MdOutlineCardMembership />
                            <span>Subscription package</span>
                        </NavLink>
                    </li> : null}
                    {/* Section B PLATFORM ADMIN*/}
                    {!AdminMenu ?
                        <li>
                            <NavLink
                                to={RouteNavigation.dashboard}
                                onClick={handleNavbarClick}
                                className={({ isActive, isPending }) =>
                                    `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                    }`
                                }
                            >
                                <img src={Dashboard} alt="dashboard" />
                                <span>Dashboard</span>
                            </NavLink>
                        </li> : null}
                    {!AdminMenu ? <li>
                        <Accordion image={SalesReport} title={"Sales Report"} content={<div className="mt-4">
                            <ul className="font-medium">
                                <NavLink
                                    to={RouteNavigation.listSales}
                                    onClick={handleNavbarClick}
                                    className={({ isActive, isPending }) =>
                                        `flex items-center gap-2 px-5 py-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl" : ""
                                        }`
                                    }
                                >
                                    List of sales
                                </NavLink>
                                <NavLink
                                    to={RouteNavigation.itemSale}
                                    onClick={handleNavbarClick}
                                    className={({ isActive, isPending }) =>
                                        `flex items-center gap-2 px-5 py-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl" : ""
                                        }`
                                    }
                                >
                                    Item wise sales
                                </NavLink>
                                <NavLink
                                    to={RouteNavigation.categorySale}
                                    onClick={handleNavbarClick}
                                    className={({ isActive, isPending }) =>
                                        `flex items-center gap-2 px-5 py-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl" : ""
                                        }`
                                    }
                                >
                                    Category wise sales
                                </NavLink>
                                <NavLink
                                    to={RouteNavigation.employeeSale}
                                    onClick={handleNavbarClick}
                                    className={({ isActive, isPending }) =>
                                        `flex items-center gap-2 px-5 py-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl" : ""
                                        }`
                                    }
                                >
                                    Employee wise sales
                                </NavLink>
                                <NavLink
                                    to={RouteNavigation.cancelledSale}
                                    onClick={handleNavbarClick}
                                    className={({ isActive, isPending }) =>
                                        `flex items-center gap-2 px-5 py-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl" : ""
                                        }`
                                    }
                                >
                                    Cancelled sales
                                </NavLink>
                            </ul>
                        </div>} />
                    </li> : null}
                    <li className="hidden">
                        <div className="flex items-center gap-2">
                            <img src={PurchaseReport} alt="purchase_report" />
                            <span>Purchase Reports</span>
                        </div>
                    </li>
                    <li className="hidden">
                        <div className="flex items-center gap-2">
                            <img src={StockReport} alt="stock_report" />
                            <span>Stock Report</span>
                        </div>
                    </li>
                    <li className="hidden">
                        <div className="flex items-center gap-2">
                            <img src={ActivityReport} alt="activity_report" />
                            <span>Activity Report</span>
                        </div>
                    </li>
                    <li className="hidden">
                        <div className="flex items-center gap-2">
                            <img src={UserSettings} alt="user_settings" />
                            <span>User Settings</span>
                        </div>
                    </li>
                    {!AdminMenu ? <li>
                        <NavLink
                            to={RouteNavigation.acountProfile}
                            onClick={handleNavbarClick}
                            className={({ isActive, isPending }) =>
                                `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                }`
                            }
                        >
                            <AiOutlineProfile />
                            <span>Account Profile</span>
                        </NavLink>
                    </li> : null}
                    {!AdminMenu ? <li>
                        <NavLink
                            to={RouteNavigation.changePassword}
                            onClick={handleNavbarClick}
                            className={({ isActive, isPending }) =>
                                `flex items-center gap-2 ${isPending ? "pending" : isActive ? "active bg-gray-200 rounded-2xl px-5 py-2" : ""
                                }`
                            }
                        >
                            <img src={ChangePassword} alt="change_password" />
                            <span>Change Password</span>
                        </NavLink>
                    </li> : null}
                    {!AdminMenu ? <li>
                        <div className="flex items-center gap-2">
                            <IoHelpCircleOutline />
                            <span>Help</span>
                        </div>
                    </li> : null}
                </ul>
            </nav>
        </aside>
    )
}