import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

interface ProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<any>(undefined);

export const AuthProvider = ({ children }: ProviderProps) => {
  const [token, setToken] = useState(Cookies.get('userToken') || null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const allowedRoutes = ['/signup', '/reset-password'];
    if (!allowedRoutes.includes(location.pathname)) {
      const storedToken = Cookies.get('userToken');
      if (!storedToken) {
        navigate('/', { replace: true });
      } else if (!token) {
        setToken(storedToken);
      }
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const rememberMe = Cookies.get('rememberMe') === 'true';
      if (!rememberMe) {
        Cookies.remove('userToken');
        setToken(null);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    const axiosInterceptor = axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response && error.response.status === 401) {
          handleUnauthorizedResponse();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [token, location.pathname, navigate, setToken]);

  const login = (userToken: any, rememberMe: any) => {
    const expiration = rememberMe ? undefined : undefined;
    Cookies.set('userToken', userToken, expiration);
    if (rememberMe) {
      Cookies.set('rememberMe', 'true', expiration);
    } else {
      Cookies.set('rememberMe', 'true', expiration);
    }
    setToken(userToken);
  };

  const logout = () => {
    Cookies.remove('userToken');
    Cookies.remove('rememberMe');
    setToken(null);
  };

  const handleUnauthorizedResponse = () => {
    logout();
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
