import React from "react";
import { AxiosError } from 'axios';
import { Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Input } from "@ui";
import { MdAutorenew } from "react-icons/md";
import { BiDetail, BiSort } from "react-icons/bi";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { RouteNavigation } from "../../Routes";
import { Pagination } from "@mui/material";
import { GetSubscriptionAccounts, GetBussinessUnit } from "@lib";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { ShowLoadingAnimation, ShowErrorLoadingAnimation, ShowNoDataAnimation } from '@common';
import { Default } from "@/lib/constant";

function SubscriptionAccount() {
    const [open, setOpen] = React.useState(false);
    const [subscriptionAccountId, setSubscriptionAccountId] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(1);
    const [searchLetter, setSearchLetter] = React.useState("")
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { isPending, isError, data, error, isFetching } = useQuery({
        queryKey: ['SubscriptionAccountDetails', pageIndex, searchLetter],
        queryFn: () => GetSubscriptionAccounts({ index: pageIndex, size: Default.pageSize, startLetter: searchLetter }),
    })
    const { data: bussinessUnit } = useQuery({
        queryKey: ['BussinessUnitDetails', subscriptionAccountId],
        queryFn: () => GetBussinessUnit(subscriptionAccountId),
    })
    const handleChange = (event: any, value: React.SetStateAction<number>) => {
        setPageIndex(value);
    };
    const handleOpen = (subscriptionId: string) => {
        setSubscriptionAccountId(subscriptionId);
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    return (
        <div className="px-5 py-1">
            <div className="py-2 border-b-2">
                <h2 className="text-xl font-semibold py-2">Subscription Account</h2>
            </div>
            <div className="pt-4">
                <div className="flex justify-end"><Input className="w-full sm:w-[240px]" onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setSearchLetter(e.target.value)} placeholder="Search" /></div>
                <section className="py-2 relative">
                    <div className="overflow-auto">
                        <Table className="text-xs">
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Sl.No</TableHead>
                                    <TableHead>
                                        <span className="flex gap-2 items-center">
                                            Bussiness Account Name
                                        </span>
                                    </TableHead>
                                    <TableHead>Mobile No</TableHead>
                                    <TableHead>Email</TableHead>
                                    <TableHead>Validity</TableHead>
                                    <TableHead>Action</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {!isError && !isPending && !isFetching && data?.SubscriptionAccountList?.map((sub: any, index: number) => {
                                    const subscriptionId: string = sub?.SUA_Uid;
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{sub.SUA_Name}</TableCell>
                                            <TableCell>{sub.SUA_Mobile}</TableCell>
                                            <TableCell>{sub.SUA_Email}</TableCell>
                                            <TableCell className="text-center">{sub.SUA_Validity}</TableCell>
                                            <TableCell>
                                                <div className="flex justify-between items-center">
                                                    <div className="group relative">
                                                        <span className="cursor-pointer" onClick={() => { queryClient.setQueryData(['renewalData'], sub); navigate(RouteNavigation.renewal); }}><MdAutorenew size={"1rem"} /></span>
                                                        <span className="hidden group-hover:block absolute top-[-44px] left-[-70px] transform bg-white border border-1 shadow-2xl py-2 px-4 rounded transition-opacity duration-300">
                                                            Renew
                                                        </span>
                                                    </div>
                                                    <div className="group relative">
                                                        <BiDetail onClick={() => handleOpen(subscriptionId)} />
                                                        <span className="hidden group-hover:block absolute top-[-44px] left-[-70px] transform bg-white border border-1 shadow-2xl py-2 px-4 rounded transition-opacity duration-300">
                                                            Details
                                                        </span>
                                                    </div>
                                                    <div className="group relative">
                                                        <input type="checkbox" className="w-[1rem]" />
                                                        <span className="hidden group-hover:block absolute top-[-44px] left-[-70px] transform bg-white border border-1 shadow-2xl py-2 px-4 rounded transition-opacity duration-300">
                                                            Action
                                                        </span>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box className="absolute p-2 top-[25%] right-[10%] left-[10%]  transform translate[-50%, -50%] bg-white shadow-2xl rounded-2xl focus:outline-none">
                                        <h2 className="text-xl font-semibold py-2">Business Unit Details</h2>
                                        <div className="max-h-[400px] overflow-y-auto">
                                            <Table>
                                                <TableHeader style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                                                    <TableRow>
                                                        <TableHead>Sl.No</TableHead>
                                                        <TableHead>Company Name</TableHead>
                                                        <TableHead>Key</TableHead>
                                                        <TableHead>Address</TableHead>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody>
                                                    {bussinessUnit?.data?.map((unit: any, index: number) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{unit.BUS_Name}</TableCell>
                                                                <TableCell>{unit.BUS_LicenceKey}</TableCell>
                                                                <TableCell>{unit.BUS_Address}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Box>
                                </Modal>
                            </TableBody>
                        </Table>
                    </div></section>
                <div className="relative mb-10">
                    {!isPending && !isFetching && !isError && !data?.SubscriptionAccountList?.length && <div className="w-64 m-auto text-center"><ShowNoDataAnimation /></div>}
                    {isPending && <div className="w-32 m-auto text-center"><ShowLoadingAnimation /></div>}
                    {data?.SubscriptionAccountList?.length && !isError ? <div className="flex justify-end">
                        <Pagination count={data.TotalPages} page={pageIndex} onChange={handleChange} size="medium" />
                    </div> : null}
                    {!isPending && isFetching && <div className="w-32 m-auto text-center absolute top-[50%] left-[50%]"><ShowLoadingAnimation /></div>}
                    {!isPending && !isFetching && isError && (
                        <div className="w-32 m-auto font-bold text-center absolute top-[50%] left-[50%]">
                            <div><ShowErrorLoadingAnimation /></div>
                            <div>
                                {
                                    (error instanceof AxiosError) ? error.response?.data?.Message : 'An error occurred'
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SubscriptionAccount;