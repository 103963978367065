import React from "react";
import { Button, Input, Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@ui";
import { useQuery } from "@tanstack/react-query";
import { useNavbarContext } from "@lib";
import { RenewalData } from "@/lib/types";

function RenewalSubscription() {
    const { isNavbarOpen } = useNavbarContext();
    const { data: renewal } = useQuery<RenewalData>({ queryKey: ['renewalData'], });
    return (
        <div className="px-5">
            <div className="py-2 border-b-2">
                <h2 className="text-xl font-semibold py-2">Renewal</h2>
                <div className="flex flex-col items-end text-sm">
                    <span>Bussiness Account Name : {renewal?.SUA_Name}</span>
                    <span>Mobile Number : {renewal?.SUA_Mobile}</span>
                </div>
            </div>
            <div className="bg-gray-100 rounded-b-2xl p-5 space-y-8">
                <div className={`flex flex-col md:flex-row gap-6`}>
                    <span className="flex flex-col sm:flex-row sm:items-center gap-2 col-span-2">Invoice No
                        <Input className="w-full sm:w-[150px]" />
                    </span>
                    <span className="flex flex-col sm:flex-row sm:items-center gap-2 col-span-2">
                        Invoice Date <Input variant={"calender"} className="w-full sm:w-[150px]" type="date" />
                    </span>
                </div>
                <div className="grid grid-col-2 lg:grid-cols-3 gap-5">
                    <span>
                        Subscription Plan
                        <div>
                            <Select>
                                <SelectTrigger className="w-full sm:w-[200px]">
                                    <SelectValue placeholder="Select Plan" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="light">One Month Plan</SelectItem>
                                    <SelectItem value="dark">Six Month Plan</SelectItem>
                                    <SelectItem value="system">One Year Plan</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </span>
                    <span className={`flex flex-col ${isNavbarOpen && window.innerWidth <= 768 ? "sm:flex-col" : "sm:flex-row"} sm:flex-row gap-2`}>
                        <span>
                            Type
                            <div>
                                <Input className={`w-full ${isNavbarOpen ? "sm:w-full" : "sm:w-[150px]"} `} />
                            </div>
                        </span>
                        <span>
                            Days
                            <div>
                                <Input className={`w-full ${isNavbarOpen ? "sm:w-full" : "sm:w-[150px]"} `} />
                            </div>
                        </span>
                    </span>
                </div>
                <div className={`grid ${isNavbarOpen && window.innerWidth <= 768 ? "sm:grid-cols-2" : "sm:grid-cols-3"} grid-cols-1 lg:grid-cols-4 xl:grid-cols-5 gap-5`}>
                    <span>
                        Currency
                        <div>
                            <Select>
                                <SelectTrigger className="w-full sm:w-[150px]">
                                    <SelectValue placeholder="Select currency" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="light">INR</SelectItem>
                                    <SelectItem value="dark">USD</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </span>
                    <span>
                        Price
                        <div>
                            <Input className="w-full sm:w-[150px]" />
                        </div>
                    </span>
                    <span>
                        Mode of Pay
                        <div>
                            <Select>
                                <SelectTrigger className="w-full sm:w-[150px]">
                                    <SelectValue placeholder="Select MOP" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="light">Cash</SelectItem>
                                    <SelectItem value="dark">Credit</SelectItem>
                                    <SelectItem value="dark">Debit</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </span>
                    <span>
                        Payment Reference
                        <div>
                            <Input className="w-full sm:w-[150px]" />
                        </div>
                    </span>
                    <span>
                        Remark
                        <div>
                            <Input className="w-full sm:w-[150px]" />
                        </div>
                    </span>
                </div>
                <div className="flex justify-end gap-5">
                    <Button variant={"secondary"} className="w-fit px-5">Cancel</Button>
                    <Button className="w-fit px-5">Renew</Button>
                </div>
            </div>
        </div>
    )
}

export default RenewalSubscription;