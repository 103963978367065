import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"
import { VariantProps, cva } from "class-variance-authority";
import { FC } from "react";

const ProgressVariant = cva(
  "relative h-1 w-full overflow-hidden rounded-full my-2",
  {
    variants: {
      variant: {
        default: "",
        primary: "bg-[#2D00C6]",
        secondary: "bg-[#CE2F00]",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

type ProgressProps = VariantProps<typeof ProgressVariant> & {
  className?: string,
  type?: string,
  value: number,
}

const Progress: FC<ProgressProps> = (
  ({ className, variant, value, ...props }) => {
    return (
      <ProgressPrimitive.Root
        value={value}
        className={ProgressVariant({ variant, className })}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className="h-full w-full flex-1 bg-white transition-all duration-300"
          style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
        />
      </ProgressPrimitive.Root>
    )
  }
)

export { Progress }