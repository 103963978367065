import React from "react";
import { Header } from "@common";
import { Outlet } from "react-router-dom";
import { useNavbarContext } from '@lib';

export default function Dashboard() {
  const { isNavbarOpen } = useNavbarContext();
  return (
    <>
      <Header />
      <div className={`${isNavbarOpen ? "sm:pl-[18rem]" : ""} relative transition-all duration-300 delay-0`}>
        <Outlet />
        {isNavbarOpen ? <div className="rounded-md h-screen absolute sm:hidden bottom-0 left-0 right-0 top-0 w-full overflow-hidden bg-black bg-fixed opacity-50"></div> : null}
      </div>
    </>
  );
}
