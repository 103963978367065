import React from "react";
import { AxiosError } from "axios";
import { Table, TableHead, TableRow, TableCell, TableHeader, TableBody, Input, Button } from "@ui";
import { MdAutorenew } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
import { Pagination } from "@mui/material";
import { GetDashboardData, GetBussinessUnit } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { ShowLoadingAnimation, ShowErrorLoadingAnimation, ShowNoDataAnimation } from '@common';
import { Default } from "@/lib/constant";

function AdminHomePage() {
    const [subscriptionAccountId, setSubscriptionAccountId] = React.useState("");
    const [pageIndex, setPageIndex] = React.useState(1);
    const currentDate = new Date().toISOString().split('T')[0];
    const [dateFrom, setDateFrom] = React.useState(currentDate);
    const [dateTo, setDateTo] = React.useState(currentDate);
    const [activeMenu, setActiveMenu] = React.useState("total");
    const [open, setOpen] = React.useState(false);

    const { isPending, isError, data, error, isFetching } = useQuery({
        queryKey: ['SubscriptionPackageDetails', pageIndex, dateFrom, dateTo],
        queryFn: () => GetDashboardData({ index: pageIndex, size: Default.pageSize, datefrom: dateFrom, dateto: dateTo }),
    })
    const { data: bussinessUnit } = useQuery({
        queryKey: ['BussinessUnitDetails', subscriptionAccountId],
        queryFn: () => GetBussinessUnit(subscriptionAccountId),
    })

    const handleChange = (_event: any, value: React.SetStateAction<number>) => {
        setPageIndex(value);
    };
    const handleFromDate = (event: { target: { value: string; }; }) => {
        setDateFrom(event.target.value as string);
    };
    const handleToDate = (event: { target: { value: string; }; }) => {
        setDateTo(event.target.value as string);
    };
    const handleOpen = (subscriptionId: string) => {
        setSubscriptionAccountId(subscriptionId);
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const RenewTotalPages = data?.RenewToday?.totalPage;
    const ExpiringTotalPages = data?.ExpirationWithin20Days?.totalPage;
    const ExpiringAccount = data?.ExpirationWithin20Days;
    const AccountRenewed = data?.RenewToday;
    let ListAccounts: {
        Count: number; Data: any
    };
    if (activeMenu === "total") {
        ListAccounts = AccountRenewed;
    } else {
        ListAccounts = ExpiringAccount;
    }
    return (
        <div className="px-4">
            <div className="py-2 border-b-2">
                <h2 className="text-xl font-semibold py-2">Dashboard</h2>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 py-5 gap-y-2 gap-x-4">
                <div className={`${activeMenu === 'total' ? "shadow-[4px_4px_0px_0px_#22c55e]" : ""} rounded-xl transition-all duration-200`} onClick={() => setActiveMenu('total')}>
                    <div className="relative h-full p-3 bg-white border-2 border-green-500 rounded-xl">
                        <div className="flex items-center mb-5">
                            <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">Total number of accounts</h3>
                        </div>
                        <p className="my-2 px-3 text-gray-600 absolute bottom-0">10</p>
                    </div>
                </div>
                <div className={`${activeMenu === 'expiring' ? "shadow-[4px_4px_0px_0px_#3b82f6]" : ""} rounded-xl transition-all duration-200`} onClick={() => setActiveMenu('expiring')}>
                    <div className="relative h-full p-3 bg-white border-2 border-blue-500 rounded-xl">
                        <div className="flex items-center mb-5">
                            <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">Accounts going to expire</h3>
                        </div>
                        <p className="my-2 px-3 text-gray-600 absolute bottom-0">{ExpiringAccount ? ExpiringAccount?.Count : "0"}</p>
                    </div>
                </div>
                <div className={`${activeMenu === 'expired' ? "shadow-[4px_4px_0px_0px_#f43f5e]" : ""} rounded-xl transition-all duration-200`} onClick={() => setActiveMenu('expired')}>
                    <div className="relative h-full p-3 border-2 border-rose-500 rounded-xl">
                        <div className="flex items-center mb-5">
                            <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">Account Expired</h3>
                        </div>
                        <p className="my-2 px-3 text-gray-600 absolute bottom-0">{ExpiringAccount ? ExpiringAccount?.Count : "0"}</p>
                    </div>
                </div>
                <div className={`${activeMenu === 'renewed' ? "shadow-[4px_4px_0px_0px_#f97316]" : ""} rounded-xl transition-all duration-200`} onClick={() => setActiveMenu('renewed')}>
                    <div className="relative h-full p-3 bg-white border-2 border-orange-500 rounded-xl">
                        <div className="flex items-center mb-5">
                            <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">Renewed on this month</h3>
                        </div>
                        <p className="my-2 px-3 text-gray-600 absolute bottom-0">{ExpiringAccount ? ExpiringAccount?.Count : "0"}</p>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-x-4">
                <div className="flex justify-end gap-3 w-full md:w-fit items-center text-black">
                    <div className="grid w-fit">
                        <span>From</span>
                        <Input variant={"calender"} className="w-full" defaultValue={currentDate} type="date" onChange={handleFromDate} />
                    </div>
                    <div className="grid w-fit">
                        <span>To</span>
                        <Input variant={"calender"} defaultValue={currentDate} type="date" onChange={handleToDate} />
                    </div>
                </div>
            </div>

            <div>
                <p className="mt-5 font-semibold">
                    Accounts {activeMenu ? "Renewed" : "going to expire"}
                </p>
                <div className={`${isError || isFetching ? "opacity-50" : ""} overflow-auto relative`}>
                    <Table className="text-xs">
                        <TableHeader>
                            <TableRow>
                                <TableHead>Sl.No</TableHead>
                                <TableHead>Bussiness Account Name</TableHead>
                                <TableHead>Mobile No</TableHead>
                                <TableHead>Validity</TableHead>
                                <TableHead>Action</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {!isError && ListAccounts?.Data?.map((accounts: any, index: number) => {
                                const subscriptionId: string = accounts?.SUA_Uid;
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{accounts.SUA_Name}</TableCell>
                                        <TableCell>{accounts.SUA_Mobile}</TableCell>
                                        <TableCell>{accounts.SUA_Validity}</TableCell>
                                        <TableCell>
                                            <div className="flex justify-between items-center">
                                                <div className="group relative">
                                                    <Link to={RouteNavigation.renewal}>
                                                        <MdAutorenew size={"1rem"} />
                                                    </Link>
                                                    <span className="hidden group-hover:block absolute top-[-44px] left-[-70px] transform bg-white border border-1 shadow-2xl py-2 px-4 rounded transition-opacity duration-300">
                                                        Renew
                                                    </span>
                                                </div>
                                                <div className="group relative">
                                                    <BiDetail size={"1rem"} onClick={() => handleOpen(subscriptionId)} />
                                                    <span className="hidden group-hover:block absolute top-[-44px] left-[-70px] transform bg-white border border-1 shadow-2xl py-2 px-4 rounded transition-opacity duration-300">
                                                        Details
                                                    </span>
                                                </div>
                                                <div className="group relative">
                                                    <input type="checkbox" className="w-[1rem]" />
                                                    <span className="hidden group-hover:block absolute top-[-44px] left-[-70px] transform bg-white border border-1 shadow-2xl py-2 px-4 rounded transition-opacity duration-300">
                                                        Action
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box className="absolute p-2 top-[25%] right-[10%] left-[10%]  transform translate[-50%, -50%] bg-white shadow-2xl rounded-2xl focus:outline-none">
                                    <h2 className="text-xl font-semibold py-2">Business Unit Details</h2>
                                    <div className="max-h-[400px] overflow-y-auto">
                                        <Table>
                                            <TableHeader style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                                                <TableRow>
                                                    <TableHead>Sl.No</TableHead>
                                                    <TableHead>Company Name</TableHead>
                                                    <TableHead>Key</TableHead>
                                                    <TableHead>Address</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {bussinessUnit?.data?.map((unit: any, index: number) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{unit.BUS_Name}</TableCell>
                                                            <TableCell>{unit.BUS_LicenceKey}</TableCell>
                                                            <TableCell>{unit.BUS_Address}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Box>
                            </Modal>
                        </TableBody>
                    </Table>
                </div>
                <div className="relative">
                    {!isPending && !isFetching && !isError && !ListAccounts?.Data?.length && <div className="w-64 m-auto text-center"><ShowNoDataAnimation /></div>}
                    {isPending && <div className="w-32 m-auto text-center"><ShowLoadingAnimation /></div>}
                    {!isError && ListAccounts?.Count !== 0 && <div className="flex justify-end">
                        <Pagination count={activeMenu ? RenewTotalPages : ExpiringTotalPages} page={pageIndex} onChange={handleChange} size="medium" />
                    </div>}
                    {!isPending && isFetching && <div className="w-32 m-auto text-center absolute top-[50%] left-[50%]"><ShowLoadingAnimation /></div>}
                    {!isPending && !isFetching && isError && (
                        <div className="w-32 m-auto font-bold text-center absolute top-[50%] left-[50%]">
                            <div><ShowErrorLoadingAnimation /></div>
                            <div>
                                {
                                    (error instanceof AxiosError) ? error.response?.data?.Message : 'An error occurred'
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdminHomePage;