import React, { ReactNode, useState } from 'react';
import { ResetAnimation } from '@common';
import Background from "@assets/background_01.svg";
import { Button } from '@ui';
import { IoIosPhonePortrait } from "react-icons/io";
import { RegistrationNumberValidation, VerifyOTP, ForgetUserPassword } from "@lib";
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { RouteNavigation } from '../Routes';

type NumberValidation = {
    phoneNumber?: number;
}

type otpVerification = {
    OTP?: number
}

type ResetUserPassword = {
    mobileNo?: number,
    Password: string,
    ConfirmPassword: string
}

function ResetPassword() {

    /* States for handling Stepper Conditions */
    const [activeStep, setActiveStep] = useState(0);
    const handlePrev = () => setActiveStep((cur) => (cur - 1) % 3);

    const navigate = useNavigate();
    const [error, setError] = React.useState<string>();
    const [registerPhoneNumber, setRegisterPhoneNumber] = React.useState<number>();

    /* Styles for stepper */
    const activeState = "bg-blue-300 text-white";
    const completedState = "bg-green-300 text-white after:border-green-900";
    const GreenProgressLine = "after:border-green-300";

    /* Conditions for stepper */
    const FirstStepperLine = `${activeStep === 1 ? GreenProgressLine : ""} ${activeStep === 2 ? GreenProgressLine : ""}`;
    const FirstStepper = `${activeStep === 0 ? activeState : ""} ${activeStep === 1 ? completedState : ""} ${activeStep === 2 ? completedState : ""}`;
    const SecondStepperLine = `${activeStep === 2 ? GreenProgressLine : ""}`;
    const SecondStepper = `${activeStep === 1 ? activeState : ""} ${activeStep === 2 ? completedState : ""} ${activeStep === 2 ? completedState : ""}`;
    const ThirdStepper = `${activeStep === 2 ? activeState : ""}`;

    /* Validation Schema */
    const ResetUserPassword = yup.object().shape({
        mobileNo: yup.number(),
        Password: yup.string()
            .required("Password is required")
            .min(4, "Password too short"),
        ConfirmPassword: yup.string()
            .required("Confirm Password is required")
            .oneOf([yup.ref("Password")], "Passwords do not match")
    });

    /* Initialize form values and error handling */
    const { register: register1, handleSubmit: handleSubmitForm1, reset: resetForm1, formState: { errors: error1 } } = useForm();
    const { handleSubmit: handleSubmitForm2, reset: resetForm2 } = useForm();
    const { register: register3, handleSubmit: handleSubmitForm3, formState: { errors }, reset: resetForm3 } = useForm<ResetUserPassword>({
        mode: "onTouched",
        resolver: yupResolver(ResetUserPassword),
    });

    const [OTP, setOTP] = useState("");
    const isOTP = OTP.length === 6;

    /* Form Submitting funcitons */
    const onSubmitForm1: SubmitHandler<NumberValidation> = async (data) => {
        setRegisterPhoneNumber(data?.phoneNumber);
        try {
            const dataResponse = await RegistrationNumberValidation(data);
            if (dataResponse.status === 200) {
                toast.success(dataResponse.data.Message || 'Sent OTP Successfully', {
                    position: "top-right",
                    closeOnClick: true,
                });
                setActiveStep((cur) => (cur + 1) % 3);
                resetForm1();
            } else {
                setError("Failed " + dataResponse.error.Message || "");
                toast.error(dataResponse.error.Message || 'Failed to sent otp', {
                    position: "top-right",
                    closeOnClick: true,
                });
            }
        } catch (error) {
            return error;
        }
    };

    const handleResendOtp = async (data: any) => {
        const newData = { ...data, phoneNumber: registerPhoneNumber };
        try {
            const dataResponse = await RegistrationNumberValidation(newData);
            if (dataResponse.status === 200) {
                toast.success('Sent OTP Successfully', {
                    position: "top-right",
                    closeOnClick: true,
                });
            } else {
                toast.error(dataResponse.error.Message || 'Failed to sent otp', {
                    position: "top-right",
                    closeOnClick: true,
                });
            }
        } catch (error) {
            return error;
        }
    }

    const onSubmitForm2: SubmitHandler<otpVerification> = async (data) => {
        const newData = { ...data, phoneNumber: registerPhoneNumber, otp: OTP };
        try {
            const dataResponse = await VerifyOTP(newData);
            if (dataResponse.status === 200) {
                toast.success(dataResponse.data.Message || "OTP Verified successfully", {
                    position: "top-right",
                    closeOnClick: true,
                });
                setActiveStep((cur) => (cur + 1) % 3);
                resetForm2();
            } else {
                toast.error(dataResponse.response.data.Message || "OTP Verified Failed", {
                    position: "top-right",
                    closeOnClick: true,
                });
                resetForm2();
            }
        } catch (error) {
            toast.error("OTP Verified Failed", {
                position: "top-right",
                closeOnClick: true,
            });
            return error;
        }
    }

    const onSubmitForm3: SubmitHandler<ResetUserPassword> = async (data) => {
        const newData = { mobileNo: registerPhoneNumber, Password: data.ConfirmPassword };
        try {
            const dataResponse = await ForgetUserPassword(newData);
            if (dataResponse.status === 202) {
                toast.success(dataResponse.data.Message || "Reset Password Successfully", {
                    position: "top-right",
                    closeOnClick: true,
                });
                resetForm3();
                navigate(RouteNavigation.login)
            } else {
                toast.error('Failed ' + dataResponse.data.Message || '', {
                    position: "top-right",
                    closeOnClick: true,
                });
            }
        } catch (error) {
            toast.error('Failed to submit', {
                position: "top-right",
                closeOnClick: true,
            });
        }
    }
    const renderContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <>
                        <form onSubmit={handleSubmitForm1(onSubmitForm1)}>
                            <h4 className="flex justify-center items-center mt-5 text-lg text-gray-800 font-medium">GET OTP</h4>
                            <div className="mb-5">
                                <span>Mobile Number*</span>
                                <input
                                    {...register1("phoneNumber", {
                                        required: "Mobile Number is required", pattern: {
                                            value: /^\d+$/,
                                            message: 'Enter a valid phone number',
                                        },
                                    })}
                                    className="w-full px-8 py-4 rounded-full font-medium bg-white shadow-lg focus:outline-none"
                                    type="text" placeholder="Enter phone number" />
                                {error1.phoneNumber && (
                                    <span className='ml-2 text-red-600'>
                                        {error1.phoneNumber.message as ReactNode}
                                    </span>
                                )}
                            </div>
                            <Button type="submit" variant={"primary"}>
                                Get OTP
                            </Button>
                            {error ? <span className='ml-2 mt-2 text-red-600'>{error}</span> : ""}
                        </form>
                    </>
                );
            case 1:
                return (
                    <>
                        <form onSubmit={handleSubmitForm2(onSubmitForm2)}>
                            <h4 className="flex justify-center items-center mt-5 text-lg text-gray-800 font-medium">Verify OTP</h4>
                            <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
                                <div>
                                    <div className='flex justify-center mb-4'>
                                        <OTPInput value={OTP} autoFocus onChange={setOTP} OTPLength={6} otpType="number" disabled={false}
                                            inputClassName="focus:outline-none"
                                            inputStyles={{ border: 0, borderBottom: "1px solid #cbcbcb" }}
                                        />
                                    </div>
                                    <ResendOTP onResendClick={handleResendOtp} maxTime={30} />
                                </div>
                            </div>
                            <div className="mt-4 flex mb-10 justify-center px-4 relative gap-2">
                                <Button onClick={() => { handlePrev(); resetForm2(); }} variant={"secondary"}>
                                    Back
                                </Button>
                                <Button type="submit" disabled={!isOTP} variant={"primary"}>
                                    Verify OTP
                                </Button>
                            </div>
                        </form>
                    </>
                )
            case 2:
                return (
                    <div className='mt-5'>
                        <form onSubmit={handleSubmitForm3(onSubmitForm3)}>
                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
                                <div className="mb-2">
                                    <span>Password*</span>
                                    <input
                                        className="w-full px-8 py-4 rounded-full font-medium bg-white shadow-lg focus:outline-none"
                                        type={"password"}
                                        placeholder="Password"
                                        {...register3("Password")}
                                    ></input>
                                    <p className="text-red-500 ml-5">{errors.Password?.message}</p>
                                </div>
                                <div className="mb-2">
                                    <span>Confirm Password*</span>
                                    <input
                                        className="w-full px-8 py-4 rounded-full font-medium bg-white shadow-lg focus:outline-none"
                                        type={"password"}
                                        placeholder="Password"
                                        {...register3("ConfirmPassword")}
                                    ></input>
                                    <p className="text-red-500 ml-5">{errors.ConfirmPassword?.message}</p>
                                </div>
                            </div>
                            <div className="mt-3 flex justify-center px-4 relative gap-2">
                                <Button onClick={() => { handlePrev(); resetForm3(); }} variant={"secondary"}>
                                    Back
                                </Button>
                                <Button type="submit" variant={"primary"}>
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </div >
                )
            default:
                return null;
        }
    };

    return (
        <>
            <div className="min-w-screen min-h-screen flex items-center justify-center px-5 py-5 bg-cover bg-no-repeat" style={{ backgroundImage: `URL(${Background})` }}>
                <div className="bg-white text-black rounded-3xl shadow-xl w-full overflow-hidden" style={{ maxWidth: "1000px" }}>
                    <div className="md:flex w-full">
                        <div className="hidden md:block w-1/2 relative">
                            <div className="absolute inset-0 bg-splash-screen"></div>
                            <div className="relative z-10 p-6">
                                <ResetAnimation />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 py-2 px-5 md:px-10">
                            <div className="text-center mb-5 mt-2">
                                <h1 className="text-center font-bold text-3xl text-gray-900">Reset Password</h1>
                                <p className='py-2'>No worries - it happens <br /> Just enter the registered mobile number</p>
                            </div>
                            <ol className="flex items-center w-full">
                                <li className={`${FirstStepperLine} flex w-[45%] items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-4 after:inline-block`}>
                                    <span className={`${FirstStepper} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
                                        <IoIosPhonePortrait />
                                    </span>
                                </li>
                                <li className={`${SecondStepperLine} flex w-[45%] items-center after:content-[''] after:w-full after:h-1 after:border-b  after:border-4 after:inline-block`}>
                                    <span className={`${SecondStepper} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
                                        <IoIosPhonePortrait />
                                    </span>
                                </li>
                                <li className="flex items-center">
                                    <span className={`${ThirdStepper} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
                                        <IoIosPhonePortrait />
                                    </span>
                                </li>
                            </ol>
                            <div>
                                <div className='w-full'>{renderContent()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;