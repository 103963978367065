import React, { useState } from "react";
import LoginImage from "@assets/login-image.svg";
import Background from "@assets/background_01.svg";
import { Link } from "react-router-dom";
import { RouteNavigation } from "../Routes";
import { useForm, SubmitHandler } from "react-hook-form"
import { UserLogin, useAuth } from "@lib";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Button } from '@ui';

interface Inputs {
    UserName: number;
    Password: string;
    rememberMe: boolean;
}
const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setIsLoading(true);
        const dataResponse = await UserLogin({ ...data, rememberMe });
        const userType = decodeURIComponent(Cookies.get("userType") || 'default');
        if (dataResponse.status === 200) {
            login(Cookies.get('userToken'), rememberMe);
            toast.success('Login Successful', {
                position: "top-right",
                closeOnClick: true,
            });
            if (Cookies.get('userType') === 'PLATFORM_ADMIN') {
                navigate(RouteNavigation.adminDashboard)
            } else if (userType === 'ACC_Admin') {
                navigate(RouteNavigation.dashboard)
            }
            setIsLoading(false);
        } else {
            toast.error('Login Failed ' + dataResponse.response.data || '', {
                position: "top-right",
                closeOnClick: true,
            });
            setIsLoading(false);
        }
        setIsLoading(false);
    };
    return (
        <div className="min-w-screen min-h-screen flex items-center justify-center px-5 py-5 bg-cover bg-no-repeat" style={{ backgroundImage: `URL(${Background})` }}>
            <div className="bg-white text-black rounded-3xl shadow-xl w-full overflow-hidden" style={{ maxWidth: "1000px" }}>
                <div className="md:flex w-full">
                    <div className="hidden md:block w-1/2 relative">
                        <div className="absolute inset-0 bg-splash-screen to-90%"></div>
                        <div className="relative z-10 p-6">
                            <img src={LoginImage} alt={"Report_checking_image"} />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 py-10 sm:px-5">
                        <div className="text-center mb-5">
                            <h1 className="font-extrabold text-3xl text-gray-900">Xenia Report App Login</h1>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="px-5">
                                <div className="mb-5">
                                    <span>Mobile Number*</span>
                                    <input
                                        {...register("UserName", {
                                            required: "Phone Number is required", pattern: {
                                                value: /^\d+$/,
                                                message: 'Enter a registered phone number',
                                            },
                                        })}
                                        className="w-full px-8 py-4 rounded-full font-medium bg-white shadow-lg focus:outline-none"
                                        type="text" placeholder="Enter Registered Number" />
                                    {errors.UserName && <p role="alert" className="text-red-500 ml-5">{errors.UserName.message}</p>}
                                </div>
                                <div className="mb-5">
                                    <span>Password*</span>
                                    <input
                                        {...register("Password", { required: "Password is Required" })}
                                        className="w-full px-8 py-4 rounded-full font-medium bg-white shadow-lg focus:outline-none"
                                        type="password" placeholder="password" />
                                    {errors.Password && <p role="alert" className="text-red-500 ml-5">{errors.Password.message}</p>}
                                </div>
                                <div className="mb-6">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <label className="gap-1 flex items-center">
                                                <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                                                <span className="text-xs sm:text-sm">Remember Me</span>
                                            </label>
                                        </div>
                                        <div>
                                            <Link to={RouteNavigation.resetPassword}>
                                                <span className="text-xs sm:text-sm underline text-blue-500 ml-2">Forget Password?</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <Button type="submit" disabled={isLoading} variant={"primary"}>
                                    {isLoading ? "Loging in..." : "Login"}
                                </Button>
                                <div className="text-center py-2">
                                    <span>Don't have an account ?
                                        <Link to={RouteNavigation.signup}>
                                            <span className="underline text-blue-500 ml-2">SignUp</span>
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;